import ajaxCrud from "@/services/ajaxCrud";
import { endpointMappingManifestazione } from "../../../constants";

export default {
  getAll(cb = null) {
    ajaxCrud.getAll(endpointMappingManifestazione, cb);
  },

  get(id, cb) {
    ajaxCrud.get(endpointMappingManifestazione, id, cb);
  },

  update(id, data = {}, cb) {
    ajaxCrud.update(endpointMappingManifestazione, id, data, cb);
  },

  create(data = {}, cb) {
    ajaxCrud.create(endpointMappingManifestazione, data, cb);
  },

  delete(id, cb) {
    ajaxCrud.delete(endpointMappingManifestazione, id, cb);
  },

  massDelete(ids, cb) {
    ajaxCrud.massDelete(endpointMappingManifestazione, ids, cb);
  },

  massUpdate(rows, cb) {
    ajaxCrud.massUpdate(endpointMappingManifestazione, rows, cb);
  },
};
