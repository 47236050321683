import ajaxCall from "../ajaxCall";
import {
  endpointLogin,
} from "../../../constants";

export default {
  login(data, cb) {
    ajaxCall
      .sendPost(endpointLogin, data)
      .then((response) => cb(response));
  },
};


