import ajaxCrud from "./ajaxCrud";
import { endpointPeriodo } from "../../constants";

export default {
  getAll(cb = null) {
    ajaxCrud.getAll(endpointPeriodo, cb);
  },

  get(id, cb) {
    ajaxCrud.get(endpointPeriodo, id, cb);
  },

  update(id, data = {}, cb) {
    ajaxCrud.update(endpointPeriodo, id, data, cb);
  },

  create(data = {}, cb) {
    ajaxCrud.create(endpointPeriodo, data, cb);
  },

  delete(id, cb) {
    ajaxCrud.delete(endpointPeriodo, id, cb);
  },

  massDelete(ids, cb) {
    ajaxCrud.massDelete(endpointPeriodo, ids, cb);
  },

  massUpdate(rows, cb) {
    ajaxCrud.massUpdate(endpointPeriodo, rows, cb);
  },
};
