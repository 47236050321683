<template>
  <div>
    <h3 class="mb-4">Gestione mappatura avvenimenti</h3>
    <div id="mappingAvvenimentoGrid"></div>
  </div>
</template>
<script>
import Vue from "vue";
import EditableGrid from "@/components/EditableGrid";
import apiAvvenimenti from "../../services/mapping/avvenimento";
import jqxgridDefinition from "../../helpers/jqxgridDefinition";

let source = {};
let columns = [];
let instanceMapEventGrid = null;
let newRowPlaceholder = null;

export default {
  beforeCreate() {
    var providerSource = jqxgridDefinition.getFornitore().source;
    //eslint-disable-next-line
    let providerAdapter = new jqx.dataAdapter(providerSource, {
      autoBind: true,
    });

    var definition = jqxgridDefinition.getMappingAvvenimento(providerAdapter);
    source = definition.source;
    columns = definition.columns;
    newRowPlaceholder = definition.newRowPlaceholder;
  },
  mounted() {
    var MapAvvenimentoGrid = Vue.extend(EditableGrid);
    instanceMapEventGrid = new MapAvvenimentoGrid({
      propsData: {
        columns: columns,
        source: source,
      },
    });
    instanceMapEventGrid.$on("createRow", this.createAvvenimento);
    instanceMapEventGrid.$on("updateRows", this.updateAvvenimenti);
    instanceMapEventGrid.$on("deleteRows", this.deleteAvvenimenti);

    var newDiv = document.createElement("div");
    newDiv.setAttribute("id", "mappingAvvenimentoDynamicGrid");
    document.getElementById("mappingAvvenimentoGrid").appendChild(newDiv);
    instanceMapEventGrid.$mount("#mappingAvvenimentoDynamicGrid");
  },
  beforeDestroy() {
    instanceMapEventGrid.$destroy();
  },
  methods: {
    createAvvenimento() {
      apiAvvenimenti.create(newRowPlaceholder, () => {
        instanceMapEventGrid.updateBoundData("data");
      });
    },
    updateAvvenimento(id, row) {
      apiAvvenimenti.update(id, row, () => {
        instanceMapEventGrid.updateBoundData("data");
      });
    },
    updateAvvenimenti(rows) {
      apiAvvenimenti.massUpdate(rows, () => {
        instanceMapEventGrid.updateBoundData("data");
      });
    },
    deleteAvvenimenti(ids) {
      apiAvvenimenti.massDelete(ids, () => {
        instanceMapEventGrid.updateBoundData("data");
      });
    },
  },
};
</script>