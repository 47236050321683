import ajaxCrud from "./ajaxCrud";
import { endpointUtente } from "../../constants";

export default {
  getAll(cb = null) {
    ajaxCrud.getAll(endpointUtente, cb);
  },

  get(id, cb) {
    ajaxCrud.get(endpointUtente, id, cb);
  },

  update(id, data = {}, cb) {
    ajaxCrud.update(endpointUtente, id, data, cb);
  },

  create(data = {}, cb) {
    ajaxCrud.create(endpointUtente, data, cb);
  },

  delete(id, cb) {
    ajaxCrud.delete(endpointUtente, id, cb);
  },

};
