<template>
  <div>
    <h3 class="mb-4">Gestione descrizioni esiti</h3>
    <div id="descrizioneEsitoGrid"></div>
  </div>
</template>
<script>
import Vue from "vue";
import EditableGrid from "@/components/EditableGrid";
import apiDescrizioneEsito from "../services/descrizioneEsito";
import jqxgridDefinition from "../helpers/jqxgridDefinition";

let source = {};
let columns = [];
let instanceOutcomeListGrid = null;
let newRowPlaceholder = null;

export default {
  beforeCreate() {
    var marketSource = jqxgridDefinition.getDescrizioneScommessa().source;
    //eslint-disable-next-line
    let marketAdapter = new jqx.dataAdapter(marketSource, { autoBind: true });

    var definition = jqxgridDefinition.getDescrizioneEsito(marketAdapter);

    source = definition.source;
    columns = definition.columns;
    newRowPlaceholder = definition.newRowPlaceholder;
  },
  mounted() {
    var DescrizioneEsitoGrid = Vue.extend(EditableGrid);
    instanceOutcomeListGrid = new DescrizioneEsitoGrid({
      propsData: {
        columns: columns,
        source: source,
      },
    });
    instanceOutcomeListGrid.$on("createRow", this.createDescrizioneEsito);
    instanceOutcomeListGrid.$on("updateRows", this.updateDescrizioneEsiti);
    instanceOutcomeListGrid.$on("deleteRows", this.deleteDescrizioneEsiti);

    var newDiv = document.createElement("div");
    newDiv.setAttribute("id", "statoDynamicGrid");
    document.getElementById("descrizioneEsitoGrid").appendChild(newDiv);
    instanceOutcomeListGrid.$mount("#statoDynamicGrid");
    //instanceOutcomeListGrid.$mount("#descrizioneEsitoGrid");
  },

  beforeDestroy() {
    instanceOutcomeListGrid.$destroy();
  },

  methods: {
    createDescrizioneEsito() {
      apiDescrizioneEsito.create(newRowPlaceholder, () => {
        instanceOutcomeListGrid.updateBoundData("data");
      });
    },
    updateDescrizioneEsito(id, row) {
      apiDescrizioneEsito.update(id, row, () => {
        instanceOutcomeListGrid.updateBoundData("data");
      });
    },
    updateDescrizioneEsiti(rows) {
      apiDescrizioneEsito.massUpdate(rows, () => {
        instanceOutcomeListGrid.updateBoundData("data");
      });
    },
    deleteDescrizioneEsiti(ids) {
      apiDescrizioneEsito.massDelete(ids, () => {
        instanceOutcomeListGrid.updateBoundData("data");
      });
    },
  },
};
</script>