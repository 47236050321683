import ajaxCrud from "./ajaxCrud";
import { endpointDisciplina } from "../../constants";

export default {
  getAll(cb = null) {
    ajaxCrud.getAll(endpointDisciplina, cb);
  },

  get(id, cb) {
    ajaxCrud.get(endpointDisciplina, id, cb);
  },

  update(id, data = {}, cb) {
    ajaxCrud.update(endpointDisciplina, id, data, cb);
  },

  create(data = {}, cb) {
    ajaxCrud.create(endpointDisciplina, data, cb);
  },

  delete(id, cb) {
    ajaxCrud.delete(endpointDisciplina, id, cb);
  },

  massDelete(ids, cb) {
    ajaxCrud.massDelete(endpointDisciplina, ids, cb);
  },

  massUpdate(rows, cb) {
    ajaxCrud.massUpdate(endpointDisciplina, rows, cb);
  },
};
