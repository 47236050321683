<template>
  <div>
    <h3 class="mb-4">Gestione Impostazioni Evento</h3>
    <div id="impostazioniGrid"></div>
  </div>
</template>
<script>
import Vue from "vue";
import EditableGrid from "@/components/EditableGrid";
import apiImpostazioniEvento from "../services/impostazioniEvento";
import jqxgridDefinition from "../helpers/jqxgridDefinition";

let source = {};
let columns = [];
let instanceStatusGrid = null;
let newRowPlaceholder = null;

export default {
  beforeCreate() {
    var providerSource = jqxgridDefinition.getFornitore().source;
    //eslint-disable-next-line
    let providerAdapter = new jqx.dataAdapter(providerSource, {
      autoBind: true,
    });

    source = jqxgridDefinition.getImpostazioniEvento(providerAdapter).source;
    columns = jqxgridDefinition.getImpostazioniEvento(providerAdapter).columns;
    newRowPlaceholder =
      jqxgridDefinition.getImpostazioniEvento(
        providerAdapter
      ).newRowPlaceholder;
  },
  mounted() {
    var ImpGrid = Vue.extend(EditableGrid);
    instanceStatusGrid = new ImpGrid({
      propsData: {
        columns: columns,
        source: source,
      },
    });
    instanceStatusGrid.$on("createRow", this.createImp);
    instanceStatusGrid.$on("updateRows", this.updateImp);
    instanceStatusGrid.$on("deleteRows", this.deleteImp);

    var newDiv = document.createElement("div");
    newDiv.setAttribute("id", "impDynamicGrid");
    document.getElementById("impostazioniGrid").appendChild(newDiv);
    instanceStatusGrid.$mount("#impDynamicGrid");
  },

  beforeDestroy() {
    instanceStatusGrid.$destroy();
  },

  methods: {
    createImp() {
      apiImpostazioniEvento.create(newRowPlaceholder, () => {
        instanceStatusGrid.updateBoundData("data");
      });
    },
    updateStato(id, row) {
      apiImpostazioniEvento.update(id, row, () => {
        instanceStatusGrid.updateBoundData("data");
      });
    },
    updateImp(rows) {
      apiImpostazioniEvento.massUpdate(rows, () => {
        instanceStatusGrid.updateBoundData("data");
      });
    },
    deleteImp(ids) {
      apiImpostazioniEvento.massDelete(ids, () => {
        instanceStatusGrid.updateBoundData("data");
      });
    },
  },
};
</script>