import ajaxCrud from "@/services/ajaxCrud";
import { endpointMappingScommessa } from "../../../constants";

export default {
  getAll(cb = null) {
    ajaxCrud.getAll(endpointMappingScommessa, cb);
  },

  get(id, cb) {
    ajaxCrud.get(endpointMappingScommessa, id, cb);
  },

  update(id, data = {}, cb) {
    ajaxCrud.update(endpointMappingScommessa, id, data, cb);
  },

  create(data = {}, cb) {
    ajaxCrud.create(endpointMappingScommessa, data, cb);
  },

  delete(id, cb) {
    ajaxCrud.delete(endpointMappingScommessa, id, cb);
  },

  massDelete(ids, cb) {
    ajaxCrud.massDelete(endpointMappingScommessa, ids, cb);
  },

  massUpdate(rows, cb) {
    ajaxCrud.massUpdate(endpointMappingScommessa, rows, cb);
  },
};
