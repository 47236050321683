<template>
  <div>
    <h3 class="mb-4">Gestione mappatura disciplina</h3>
    <div id="mappingDisciplinaGrid"></div>
  </div>
</template>
<script>
import Vue from "vue";
import EditableGrid from "@/components/EditableGrid";
import apiDiscipline from "../../services/mapping/disciplina";
import jqxgridDefinition from "../../helpers/jqxgridDefinition";

let source = {};
let columns = [];
let instanceMapSportGrid = null;
let newRowPlaceholder = null;

export default {
  beforeCreate() {
    var providerSource = jqxgridDefinition.getFornitore().source;
    //eslint-disable-next-line
    let providerAdapter = new jqx.dataAdapter(providerSource, {
      autoBind: true,
    });

    source = jqxgridDefinition.getMappingDisciplina(providerAdapter).source;
    columns = jqxgridDefinition.getMappingDisciplina(providerAdapter).columns;
    newRowPlaceholder =
      jqxgridDefinition.getMappingDisciplina(providerAdapter).newRowPlaceholder;
  },
  mounted() {
    var MapDisciplinaGrid = Vue.extend(EditableGrid);
    instanceMapSportGrid = new MapDisciplinaGrid({
      propsData: {
        columns: columns,
        source: source,
      },
    });
    instanceMapSportGrid.$on("createRow", this.createDisciplina);
    instanceMapSportGrid.$on("updateRows", this.updateDiscipline);
    instanceMapSportGrid.$on("deleteRows", this.deleteDiscipline);

    var newDiv = document.createElement("div");
    newDiv.setAttribute("id", "mappingDisciplinaDynamicGrid");
    document.getElementById("mappingDisciplinaGrid").appendChild(newDiv);
    instanceMapSportGrid.$mount("#mappingDisciplinaDynamicGrid");
  },
  beforeDestroy() {
    instanceMapSportGrid.$destroy();
  },
  methods: {
    createDisciplina() {
      apiDiscipline.create(newRowPlaceholder, () => {
        instanceMapSportGrid.updateBoundData("data");
      });
    },
    updateDisciplina(id, row) {
      apiDiscipline.update(id, row, () => {
        instanceMapSportGrid.updateBoundData("data");
      });
    },
    updateDiscipline(rows) {
      apiDiscipline.massUpdate(rows, () => {
        instanceMapSportGrid.updateBoundData("data");
      });
    },
    deleteDiscipline(ids) {
      apiDiscipline.massDelete(ids, () => {
        instanceMapSportGrid.updateBoundData("data");
      });
    },
  },
};
</script>