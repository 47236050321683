import ajaxCrud from "@/services/ajaxCrud";
import { endpointMappingNazione } from "../../../constants";

export default {
  getAll(cb = null) {
    ajaxCrud.getAll(endpointMappingNazione, cb);
  },

  get(id, cb) {
    ajaxCrud.get(endpointMappingNazione, id, cb);
  },

  update(id, data = {}, cb) {
    ajaxCrud.update(endpointMappingNazione, id, data, cb);
  },

  create(data = {}, cb) {
    ajaxCrud.create(endpointMappingNazione, data, cb);
  },

  delete(id, cb) {
    ajaxCrud.delete(endpointMappingNazione, id, cb);
  },

  massDelete(ids, cb) {
    ajaxCrud.massDelete(endpointMappingNazione, ids, cb);
  },

  massUpdate(rows, cb) {
    ajaxCrud.massUpdate(endpointMappingNazione, rows, cb);
  },
};
