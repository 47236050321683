<template>
  <div>
    <h3 class="mb-4">Gestione descrizioni scommesse</h3>
    <div id="descrizioneScommessaGrid"></div>
  </div>
</template>
<script>
import Vue from "vue";
import EditableGrid from "@/components/EditableGrid";
import apiDescrizioneScommessa from "../services/descrizioneScommessa";
import jqxgridDefinition from "../helpers/jqxgridDefinition";

let source = {};
let columns = [];
let instanceMarketListGrid = null;
let newRowPlaceholder = null;

export default {
  beforeCreate() {
    source = jqxgridDefinition.getDescrizioneScommessa().source;
    columns = jqxgridDefinition.getDescrizioneScommessa().columns;
    newRowPlaceholder = jqxgridDefinition.getDescrizioneScommessa().newRowPlaceholder;
  },
  mounted() {
    var DescrizioneScommessaGrid = Vue.extend(EditableGrid);
    instanceMarketListGrid = new DescrizioneScommessaGrid({
      propsData: {
        columns: columns,
        source: source,
      },
    });
    instanceMarketListGrid.$on("createRow", this.createDescrizioneScommessa);
    instanceMarketListGrid.$on("updateRows", this.updateDescrizioneScommesse);
    instanceMarketListGrid.$on("deleteRows", this.deleteDescrizioneScommesse);

    var newDiv = document.createElement("div");
    newDiv.setAttribute("id", "statoDynamicGrid");
    document.getElementById("descrizioneScommessaGrid").appendChild(newDiv);
    instanceMarketListGrid.$mount("#statoDynamicGrid");
    //instanceMarketListGrid.$mount("#descrizioneScommessaGrid");
  },

  beforeDestroy() {
    instanceMarketListGrid.$destroy();
  },

  methods: {
    createDescrizioneScommessa() {
      apiDescrizioneScommessa.create(newRowPlaceholder, () => {
        instanceMarketListGrid.updateBoundData("data");
      });
    },
    updateDescrizioneScommessa(id, row) {
      apiDescrizioneScommessa.update(id, row, () => {
        instanceMarketListGrid.updateBoundData("data");
      });
    },
    updateDescrizioneScommesse(rows) {
      apiDescrizioneScommessa.massUpdate(rows, () => {
        instanceMarketListGrid.updateBoundData("data");
      });
    },
    deleteDescrizioneScommesse(ids) {
      apiDescrizioneScommessa.massDelete(ids, () => {
        instanceMarketListGrid.updateBoundData("data");
      });
    },
  },
};
</script>