<template>
  <div>
    <h3 class="mb-4">Gestione mappatura manifestazioni</h3>
    <div id="mappingManifestazioneGrid"></div>
  </div>
</template>
<script>
import Vue from "vue";
import EditableGrid from "@/components/EditableGrid";
import apiManifestazioni from "../../services/mapping/manifestazione";
import jqxgridDefinition from "../../helpers/jqxgridDefinition";

let source = {};
let columns = [];
let instanceMapTournamentGrid = null;
let newRowPlaceholder = null;

export default {
  beforeCreate() {
    var providerSource = jqxgridDefinition.getFornitore().source;
    //eslint-disable-next-line
    let providerAdapter = new jqx.dataAdapter(providerSource, {
      autoBind: true,
    });

    source = jqxgridDefinition.getMappingManifestazione(providerAdapter).source;
    columns =
      jqxgridDefinition.getMappingManifestazione(providerAdapter).columns;
    newRowPlaceholder =
      jqxgridDefinition.getMappingManifestazione(
        providerAdapter
      ).newRowPlaceholder;
  },
  mounted() {
    var MapManifestazioneGrid = Vue.extend(EditableGrid);
    instanceMapTournamentGrid = new MapManifestazioneGrid({
      propsData: {
        columns: columns,
        source: source,
      },
    });
    instanceMapTournamentGrid.$on("createRow", this.createManifestazione);
    instanceMapTournamentGrid.$on("updateRows", this.updateManifestazioni);
    instanceMapTournamentGrid.$on("deleteRows", this.deleteManifestazioni);

    var newDiv = document.createElement("div");
    newDiv.setAttribute("id", "mappingManifestazioneDynamicGrid");
    document.getElementById("mappingManifestazioneGrid").appendChild(newDiv);
    instanceMapTournamentGrid.$mount("#mappingManifestazioneDynamicGrid");
  },
  beforeDestroy() {
    instanceMapTournamentGrid.$destroy();
  },
  methods: {
    createManifestazione() {
      apiManifestazioni.create(newRowPlaceholder, () => {
        instanceMapTournamentGrid.updateBoundData("data");
      });
    },
    updateManifestazione(id, row) {
      apiManifestazioni.update(id, row, () => {
        instanceMapTournamentGrid.updateBoundData("data");
      });
    },
    updateManifestazioni(rows) {
      apiManifestazioni.massUpdate(rows, () => {
        instanceMapTournamentGrid.updateBoundData("data");
      });
    },
    deleteManifestazioni(ids) {
      apiManifestazioni.massDelete(ids, () => {
        instanceMapTournamentGrid.updateBoundData("data");
      });
    },
  },
};
</script>