import Vue from "vue";
import VueRouter from "vue-router";

import Default from "@/components/layouts/Default.vue";
import FullContent from "@/components/layouts/FullContent.vue";

import Login from "@/components/auth/Login.vue";

import Home from "@/components/Home.vue";
import Utente from "@/components/Utente";
import MacchinaStreaming from "@/components/MacchinaStreaming";

import Manifestazione from "@/components/Manifestazione.vue";
import Disciplina from "@/components/Disciplina.vue";
import Fornitore from "@/components/Fornitore.vue";
import Avvenimento from "@/components/Avvenimento.vue";
import Nazione from "@/components/Nazione.vue";
import Canale from "@/components/Canale.vue";
import Stato from "@/components/Stato.vue";
import Periodo from "@/components/Periodo.vue";
import DescrizioneEsito from "@/components/DescrizioneEsito.vue";
import DescrizioneScommessa from "@/components/DescrizioneScommessa.vue";
import ImpostazioniEvento from "@/components/ImpostazioniEvento.vue";
import MappingNazione from "@/components/mapping/Nazione.vue";
import MappingDisciplina from "@/components/mapping/Disciplina.vue";
import MappingManifestazione from "@/components/mapping/Manifestazione.vue";
import MappingAvvenimento from "@/components/mapping/Avvenimento.vue";
import MappingScommessa from "@/components/mapping/Scommessa.vue";
import MappingPeriodo from "@/components/mapping/Periodo.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { layout: FullContent },
  },
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/utente",
    name: "utente",
    component: Utente,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/disciplina",
    name: "disciplina",
    component: Disciplina,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/manifestazione",
    name: "manifestazione",
    component: Manifestazione,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/fornitore",
    name: "fornitore",
    component: Fornitore,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/avvenimento",
    name: "avvenimento",
    component: Avvenimento,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/nazione",
    name: "nazione",
    component: Nazione,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/canale",
    name: "canale",
    component: Canale,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/stato",
    name: "stato",
    component: Stato,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/esito",
    name: "descrizioneesito",
    component: DescrizioneEsito,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/scommessa",
    name: "descrizionescommessa",
    component: DescrizioneScommessa,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/periodo",
    name: "periodo",
    component: Periodo,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/impostazionievento",
    name: "impostazionievento",
    component: ImpostazioniEvento,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/mapping/nazione",
    name: "mappingnazione",
    component: MappingNazione,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/mapping/disciplina",
    name: "mappingdisciplina",
    component: MappingDisciplina,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/mapping/manifestazione",
    name: "mappingmanifestazione",
    component: MappingManifestazione,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/mapping/avvenimento",
    name: "mappingavvenimento",
    component: MappingAvvenimento,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/mapping/scommessa",
    name: "mappingscommessa",
    component: MappingScommessa,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/mapping/periodo",
    name: "mappingperiodo",
    component: MappingPeriodo,
    meta: { layout: Default, requiresAuth: true },
  },
  {
    path: "/macchinastreaming",
    name: "macchinastreaming",
    component: MacchinaStreaming,
    meta: { layout: Default, requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("user")) {
      next();
    } else {
      //let user = JSON.parse(localStorage.getItem("user"));
      next({ name: "login" });
    }
  } else {
    next();
  }
});

export default router;
