<template>
  <b-row id="content" class="w-100 m-0">
    <b-col cols="12" class="d-block d-md-none p-0">
      <SidebarMobile></SidebarMobile
    ></b-col>

    <b-col
      md="2"
      class="d-none d-md-block p-0"
      style="position: fixed; top: 0; left: 0"
    >
      <Sidebar></Sidebar>
    </b-col>

    <b-col cols="12" offset-md="2" md="10" class="p-4">
      <slot />
    </b-col>
  </b-row>
</template>

<script>
import SidebarMobile from "@/components/layouts/SidebarMobile.vue";
import Sidebar from "@/components/layouts/Sidebar.vue";

export default {
  components: {
    SidebarMobile,
    Sidebar,
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/variables";
#content{
  background-color: $light-background;
}

::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $primary;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $accent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $accent-darker;
}

.alert-ejoy {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: $accent-darker;
  background-color: $accent-lighter;
  border-color: $accent-darker;
}
</style>

