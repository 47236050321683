<template>
  <div>
    <h3 class="mb-4">Gestione nazioni</h3>
    <div id="nazioneGrid"></div>
  </div>
</template>
<script>
import Vue from "vue";
import EditableGrid from "@/components/EditableGrid";
import apiNazioni from "../services/nazione";
import jqxgridDefinition from "../helpers/jqxgridDefinition";

let source = {};
let columns = [];
let instanceCountryGrid = null;
let newRowPlaceholder = null;

export default {
  beforeCreate() {
    source = jqxgridDefinition.getNazione().source;
    columns = jqxgridDefinition.getNazione().columns;
    newRowPlaceholder = jqxgridDefinition.getNazione().newRowPlaceholder;
  },
  mounted() {
    var NazioneGrid = Vue.extend(EditableGrid);
    instanceCountryGrid = new NazioneGrid({
      propsData: {
        columns: columns,
        source: source,
      },
    });
    instanceCountryGrid.$on("createRow", this.createNazione);
    instanceCountryGrid.$on("updateRows", this.updateNazioni);
    instanceCountryGrid.$on("deleteRows", this.deleteNazioni);

    var newDiv = document.createElement("div");
    newDiv.setAttribute("id", "nazioneDynamicGrid");
    document.getElementById("nazioneGrid").appendChild(newDiv);
    instanceCountryGrid.$mount("#nazioneDynamicGrid");
  },
  beforeDestroy() {
    instanceCountryGrid.$destroy();
  },
  methods: {
    createNazione() {
      apiNazioni.create(newRowPlaceholder, () => {
        instanceCountryGrid.updateBoundData("data");
      });
    },
    updateNazione(id, row) {
      apiNazioni.update(id, row, () => {
        instanceCountryGrid.updateBoundData("data");
      });
    },
    updateNazioni(rows) {
      apiNazioni.massUpdate(rows, () => {
        instanceCountryGrid.updateBoundData("data");
      });
    },
    deleteNazioni(ids) {
      apiNazioni.massDelete(ids, () => {
        instanceCountryGrid.updateBoundData("data");
      });
    },
  },
};
</script>