import ajaxCrud from "./ajaxCrud";
import { endpointManifestazione } from "../../constants";

export default {
  getAll(cb = null) {
    ajaxCrud.getAll(endpointManifestazione, cb);
  },

  get(id, cb) {
    ajaxCrud.get(endpointManifestazione, id, cb);
  },

  update(id, data = {}, cb) {
    ajaxCrud.update(endpointManifestazione, id, data, cb);
  },

  create(data = {}, cb) {
    ajaxCrud.create(endpointManifestazione, data, cb);
  },

  delete(id, cb) {
    ajaxCrud.delete(endpointManifestazione, id, cb);
  },

  massDelete(ids, cb) {
    ajaxCrud.massDelete(endpointManifestazione, ids, cb);
  },

  massUpdate(rows, cb) {
    ajaxCrud.massUpdate(endpointManifestazione, rows, cb);
  },
};
