<template>
  <div>
    <h3 class="mb-4">Gestione manifestazioni</h3>
    <div id="manifestazioneGrid"></div>
  </div>
</template>
<script>
import Vue from "vue";
import EditableGrid from "@/components/EditableGrid";
import apiManifestazioni from "../services/manifestazione";
import jqxgridDefinition from "../helpers/jqxgridDefinition";

let source = {};
let columns = [];
let instanceTournamentGrid = null;
let newRowPlaceholder = null;

export default {
  beforeCreate() {
    var countrySource = jqxgridDefinition.getNazione().source;
    //eslint-disable-next-line
    let countryAdapter = new jqx.dataAdapter(countrySource, { autoBind: true });

    var definition = jqxgridDefinition.getManifestazione(countryAdapter);
    source = definition.source;
    columns = definition.columns;
    newRowPlaceholder = definition.newRowPlaceholder;
  },

  mounted() {
    var ManifestazioneGrid = Vue.extend(EditableGrid);
    instanceTournamentGrid = new ManifestazioneGrid({
      propsData: {
        columns: columns,
        source: source,
      },
    });
    instanceTournamentGrid.$on("createRow", this.createManifestazione);
    instanceTournamentGrid.$on("updateRows", this.updateManifestazioni);
    instanceTournamentGrid.$on("deleteRows", this.deleteManifestazioni);

    var newDiv = document.createElement("div");
    newDiv.setAttribute("id", "manifestazioneDynamicGrid");
    document.getElementById("manifestazioneGrid").appendChild(newDiv);
    instanceTournamentGrid.$mount("#manifestazioneDynamicGrid");
  },
  beforeDestroy() {
    instanceTournamentGrid.$destroy();
  },
  methods: {
    createManifestazione() {
      apiManifestazioni.create(newRowPlaceholder, () => {
        instanceTournamentGrid.updateBoundData("data");
      });
    },
    updateManifestazione(id, row) {
      apiManifestazioni.update(id, row, () => {
        instanceTournamentGrid.updateBoundData("data");
      });
    },
    updateManifestazioni(rows) {
      apiManifestazioni.massUpdate(rows, () => {
        instanceTournamentGrid.updateBoundData("data");
      });
    },
    deleteManifestazioni(ids) {
      apiManifestazioni.massDelete(ids, () => {
        instanceTournamentGrid.updateBoundData("data");
      });
    },
  },
};
</script>