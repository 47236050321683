<template>
  <div>
    <h3 class="mb-4">Gestione fornitori</h3>
    <div id="fornitoreGrid"></div>
  </div>
</template>
<script>
import Vue from "vue";
import EditableGrid from "@/components/EditableGrid";
import apiFornitori from "../services/fornitore";
import jqxgridDefinition from "../helpers/jqxgridDefinition";

let source = {};
let columns = [];
let instanceProviderGrid = null;
let newRowPlaceholder = null;

export default {
  beforeCreate() {
    source = jqxgridDefinition.getFornitore().source;
    columns = jqxgridDefinition.getFornitore().columns;
    newRowPlaceholder = jqxgridDefinition.getFornitore().newRowPlaceholder;
  },
  mounted() {
    var FornitoreGrid = Vue.extend(EditableGrid);
    instanceProviderGrid = new FornitoreGrid({
      propsData: {
        columns: columns,
        source: source,
      },
    });
    instanceProviderGrid.$on("createRow", this.createFornitore);
    instanceProviderGrid.$on("updateRows", this.updateFornitori);
    instanceProviderGrid.$on("deleteRows", this.deleteFornitori);

    var newDiv = document.createElement("div");
    newDiv.setAttribute("id", "fornitoreDynamicGrid");
    document.getElementById("fornitoreGrid").appendChild(newDiv);
    instanceProviderGrid.$mount("#fornitoreDynamicGrid");
  },
  beforeDestroy() {
    instanceProviderGrid.$destroy();
  },
  methods: {
    createFornitore() {
      apiFornitori.create(newRowPlaceholder, () => {
        instanceProviderGrid.updateBoundData("data");
      });
    },
    updateFornitore(id, row) {
      apiFornitori.update(id, row, () => {
        instanceProviderGrid.updateBoundData("data");
      });
    },
    updateFornitori(rows) {
      apiFornitori.massUpdate(rows, () => {
        instanceProviderGrid.updateBoundData("data");
      });
    },
    deleteFornitori(ids) {
      apiFornitori.massDelete(ids, () => {
        instanceProviderGrid.updateBoundData("data");
      });
    },
  },
};
</script>