<template>
  <div>
    <h3 class="mb-4">Benvenuto!</h3>
    <div class="alert-ejoy">
      Benvenuto nella consolle di gestione di Ejoy! Da qui potrai navigare tutte
      le tabelle e modificarne i dati. Opera con cautela.
    </div>
    <div v-for="(group, index) in groups" :key="index">
      <h4>{{ group.label }}</h4>
      <b-row>
        <b-col
          cols="12"
          md="4"
          v-for="(route, index) in group.routes"
          :key="index"
          @click="pushRoute(route.name)"
        >
          <b-card
            :header="capitalizeFirstLetter(route.label)"
            border-variant="dark"
            header-bg-variant="light"
            header-border-variant="dark"
            align="center"
            class="mb-3"
          >
            <b-card-text>{{ route.text }}</b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { home } from "@/router/componentsRoutes";
export default {
  data: () => {
    return {
      groups: home,
    };
  },
  methods: {
    pushRoute(routeName) {
      if (this.$route.name != routeName)
        this.$router.push({
          name: routeName,
        });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.card:hover {
  cursor: pointer;
  color: $accent;
  font-weight: bold;
}
</style>