<template>
  <b-button class="w-100" @click="logout()">Logout</b-button>
</template>


<script>
import router from "@/router";
export default {
  methods: {
    logout() {
      localStorage.removeItem("user");
      router.push({
        name: "login",
      });
    },
  },
};
</script>