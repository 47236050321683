<template>
  <div id="sidebar">
    <div v-if="toggler" style="padding: 2vh 5vh; text-align: right">
      <b-icon
        icon="x-circle"
        v-b-toggle.MobileSidebar
        scale="2"
        style="color: white"
      ></b-icon>
    </div>
    <div v-else class="logo">
      <img src="@/assets/images/logo.png" />
      <span>MANAGER</span>
    </div>

    <b-nav vertical class="w-100" v-for="(group, index) in groups" :key="index">
      <span class="group-label">{{ group.label }}</span>
      <b-nav-item
        v-for="(route, index) in group.routes"
        :key="index"
        @click="pushRoute(route.name)"
        :class="checkCurrentRoute(route.name)"
      >
        {{ capitalizeFirstLetter(route.label) }}
      </b-nav-item>
    </b-nav>
    <div class="w-100 p-3">
      <Logout></Logout>
    </div>
  </div>
</template>

<script>
import Logout from "@/components/auth/Logout.vue";
import { sidebar } from "@/router/componentsRoutes";
export default {
  data: () => {
    return {
      groups: sidebar,
    };
  },
  components: { Logout },
  props: ["toggler"],
  methods: {
    pushRoute(routeName) {
      if (this.$route.name != routeName)
        this.$router.push({
          name: routeName,
        });
    },
    checkCurrentRoute(routeName) {
      return this.$route.name == routeName ? "active" : "";
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

#sidebar {
  height: 100vh;
  background-color: $primary;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 5px;
  }
  /*
  &::-webkit-scrollbar-track {
    background: $primary;
  }

  &::-webkit-scrollbar-thumb {
    background: $accent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $accent-darker;
  }*/

  .logo {
    display: flex;
    height: 10vh;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    font-weight: bold;
    img {
      height: 5vh;
    }
  }
  .group-label {
    color: $primary-lighter;
    text-transform: uppercase;
    font-weight: bold;
    padding: 8px 16px;
    //margin-left: 5px;
    opacity: 0.5;
  }
  .nav-link {
    border-left: 5px solid transparent;
    text-align: left;
    color: white;
  }
  .active .nav-link {
    background-color: transparentize($color: white, $amount: 0.9);
    color: $accent;
    border-left-color: $accent;
  }
}
</style>