let DEVELOPMENT = process.env.NODE_ENV === "development";
let endpoint = DEVELOPMENT
  ? "http://ejoymanagerbackend.test/api/backend/"
  : process.env.VUE_APP_API_ENDPOINT;

module.exports = {
  endpointLogin: endpoint + "login",

  endpoint: endpoint,

  endpointUtente: endpoint + "user",
  endpointMacchinaStreaming: endpoint + "streamingmachine",

  endpointManifestazione: endpoint + "tournament",
  endpointDisciplina: endpoint + "sport",
  endpointFornitore: endpoint + "provider",
  endpointAvvenimento: endpoint + "event",
  endpointNazione: endpoint + "country",
  endpointCanale: endpoint + "channel",
  endpointStato: endpoint + "status",
  endpointPeriodo: endpoint + "period",
  endpointImpostazioniEvento: endpoint + "settingseventasseveration",

  endpointDescrizioneScommessa: endpoint + "market/description",
  endpointDescrizioneEsito: endpoint + "outcome/description",

  endpointMappingDisciplina: endpoint + "mapping/sport",
  endpointMappingNazione: endpoint + "mapping/country",
  endpointMappingManifestazione: endpoint + "mapping/tournament",
  endpointMappingAvvenimento: endpoint + "mapping/event",
  endpointMappingScommessa: endpoint + "mapping/market",
  endpointMappingPeriodo: endpoint + "mapping/period",
};
