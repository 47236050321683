import router from "@/router";
import {
  endpointDisciplina,
  endpointFornitore,
  endpointManifestazione,
  endpointAvvenimento,
  endpointNazione,
  endpointCanale,
  endpointStato,
  endpointMappingNazione,
  endpointMappingDisciplina,
  endpointPeriodo,
  endpointDescrizioneEsito,
  endpointDescrizioneScommessa,
  endpointMappingManifestazione,
  endpointMappingAvvenimento,
  endpointMappingScommessa,
  endpointMappingPeriodo,
  endpointMacchinaStreaming,
  endpointImpostazioniEvento,
} from "../../constants";
//import $ from 'jquery';
var dateOptions = {
  weekday: "short",
  year: "numeric",
  month: "long",
  day: "numeric",
};

function cellDateFormatter(row, columnfield, value) {
  return (
    '<div class="jqx-grid-cell-right-align" style="margin-top: 8px;">' +
    new Date(value).toLocaleDateString("it-IT", dateOptions) +
    "</div>"
  );
}

function onError(XHR) {
  if (XHR.status === 401) {
    localStorage.removeItem("user");
    router.push({
      name: "login",
    });
  }
}

function onBeforeSend(XHR) {
  let user = JSON.parse(localStorage.getItem("user"));
  XHR.setRequestHeader(
    "Authorization",
    "Bearer " + (user ? user.api_token : "")
  );
}

export default {
  getDisciplina() {
    return {
      source: {
        datafields: [
          { name: "id", type: "number" },
          { name: "sport", type: "string" },
        ],
        datatype: "json",
        id: "id",
        url: endpointDisciplina + "/list",
        root: "data",
        async: false,
        loadError: function(jqXHR) {
          onError(jqXHR);
        },
        beforeSend: function(jqxXHR) {
          onBeforeSend(jqxXHR);
        },
      },
      columns: [
        { text: "Id", datafield: "id", width: 50, editable: false },
        { text: "Sport", datafield: "sport" },
      ],
      newRowPlaceholder: {
        sport: "Placeholder",
      },
    };
  },

  getFornitore() {
    return {
      source: {
        datafields: [
          { name: "id", type: "number" },
          { name: "slug", type: "string" },
          { name: "provider", type: "string" },
          { name: "endpoint_server", type: "string" },
        ],
        datatype: "json",
        id: "id",
        url: endpointFornitore + "/list",
        root: "data",
        async: false,
        loadError: function(jqXHR) {
          onError(jqXHR);
        },
        beforeSend: function(jqxXHR) {
          onBeforeSend(jqxXHR);
        },
      },
      columns: [
        { text: "Id", datafield: "id", width: 50, editable: false },
        { text: "Slug", datafield: "slug", width: 200 },
        { text: "Provider", datafield: "provider", width: 400 },
        { text: "Endpoint server", datafield: "endpoint_server" },
      ],

      newRowPlaceholder: {
        slug: "Placeholder",
        provider: "Placeholder",
      },
    };
  },

  getManifestazione(countryAdapter) {
    return {
      source: {
        datafields: [
          { name: "id", type: "number" },
          { name: "country_id", type: "number" },
          {
            name: "country",
            value: "country_id",
            values: {
              source: countryAdapter.records,
              value: "country_id",
              name: "country",
            },
          },
          { name: "tournament", type: "string" },
          { name: "created_at", type: "date" },
          { name: "updated_at", type: "date" },
        ],
        datatype: "json",
        id: "id",
        url: endpointManifestazione + "/list",
        root: "data",
        async: false,
        loadError: function(jqXHR) {
          onError(jqXHR);
        },
        beforeSend: function(jqxXHR) {
          onBeforeSend(jqxXHR);
        },
      },
      columns: [
        { text: "Id", datafield: "id", width: 50, editable: false },
        {
          text: "Country",
          datafield: "country_id",
          displayfield: "country",

          columntype: "dropdownlist",
          createeditor: (row, value, editor) => {
            editor.jqxDropDownList({
              source: countryAdapter,
              displayMember: "country",
              valueMember: "country_id",
            });
          },
        },
        { text: "Tournament", datafield: "tournament" },
        { text: "created_at", datafield: "created_at" },
        { text: "updated_at", datafield: "updated_at" },
      ],
      newRowPlaceholder: {
        sport_id: "0",
        tournament: "Placeholder",
      },
    };
  },

  getAvvenimento(
    sportAdapter,
    providerAdapter,
    channelAdapter,
    statusAdapter,
    tournamentAdapter
  ) {
    return {
      source: {
        datafields: [
          { name: "id", type: "number" },

          { name: "channel_id", type: "number" },
          {
            name: "channel",
            value: "channel_id",
            values: {
              source: channelAdapter.records,
              value: "id",
              name: "channel",
            },
          },
          { name: "sport_id", type: "number" },
          {
            name: "sport",
            value: "sport_id",
            values: {
              source: sportAdapter.records,
              value: "id",
              name: "sport",
            },
          },
          { name: "provider_id", type: "number" },
          {
            name: "provider",
            value: "provider_id",
            values: {
              source: providerAdapter.records,
              value: "id",
              name: "provider",
            },
          },
          { name: "tournament_id", type: "number" },
          {
            name: "tournament",
            value: "tournament_id",
            values: {
              source: tournamentAdapter.records,
              value: "id",
              name: "tournament",
            },
          },
          { name: "status", type: "number" },
          {
            name: "status_label",
            value: "status",
            values: {
              source: statusAdapter.records,
              value: "id",
              name: "status",
            },
          },
          { name: "is_live", type: "bool" },
          { name: "description", type: "string" },
          { name: "active", type: "bool" },
          { name: "created_at", type: "date" },
          { name: "updated_at", type: "date" },
        ],
        datatype: "json",
        id: "id",
        url: endpointAvvenimento + "/list",
        root: "data",
        async: false,
        loadError: function(jqXHR) {
          onError(jqXHR);
        },
        beforeSend: function(jqxXHR) {
          onBeforeSend(jqxXHR);
        },
      },
      columns: [
        { text: "Id", datafield: "id", width: 50, editable: false },
        {
          text: "Channel",
          datafield: "channel_id",
          displayfield: "channel",

          columntype: "dropdownlist",
          createeditor: (row, value, editor) => {
            editor.jqxDropDownList({
              source: channelAdapter,
              displayMember: "channel",
              valueMember: "id",
            });
          },
        },
        {
          text: "Sport",
          datafield: "sport_id",
          displayfield: "sport",

          columntype: "dropdownlist",
          createeditor: (row, value, editor) => {
            editor.jqxDropDownList({
              source: sportAdapter,
              displayMember: "sport",
              valueMember: "id",
            });
          },
        },
        {
          text: "Provider",
          datafield: "provider_id",
          displayfield: "provider",
          columntype: "dropdownlist",
          createeditor: (row, value, editor) => {
            editor.jqxDropDownList({
              source: providerAdapter,
              displayMember: "provider",
              valueMember: "id",
            });
          },
        },
        {
          text: "Tournament",
          datafield: "tournament_id",
          displayfield: "tournament",
          width: 200,
          columntype: "dropdownlist",
          createeditor: (row, value, editor) => {
            editor.jqxDropDownList({
              width: 200,

              source: tournamentAdapter,
              displayMember: "tournament",
              valueMember: "id",
            });
          },
        },
        {
          text: "Status",
          datafield: "status",
          displayfield: "status_label",

          columntype: "dropdownlist",
          createeditor: (row, value, editor) => {
            editor.jqxDropDownList({
              source: statusAdapter,
              displayMember: "status",
              valueMember: "id",
            });
          },
        },
        {
          text: "Live",
          datafield: "is_live",
          columntype: "checkbox",
          width: 50,
        },
        { text: "Description", datafield: "description" },
        {
          text: "Active",
          datafield: "active",
          columntype: "checkbox",
          width: 50,
        },
        {
          text: "created_at",
          datafield: "created_at",
          filtertype: "date",
          align: "right",
          cellsalign: "right",
          cellsformat: "d",
          cellsrenderer: cellDateFormatter,
        },
        {
          text: "updated_at",
          datafield: "updated_at",
          filtertype: "date",
          cellsformat: "d",
          cellsrenderer: cellDateFormatter,
        },
      ],
      newRowPlaceholder: {
        sport_id: 0,
        channel_id: 0,
        provider_id: 0,
        tournament_id: 0,
        status: 0,
        description: "Placeholder",
        active: 0,
      },
    };
  },

  getNazione() {
    return {
      source: {
        datafields: [
          { name: "id", type: "number" },
          { name: "country_id", type: "number" },
          { name: "country", type: "string" },
          { name: "ranking", type: "number" },
          { name: "slug", type: "string" },
        ],
        datatype: "json",
        id: "id",
        url: endpointNazione + "/list",
        root: "data",
        async: false,
        loadError: function(jqXHR) {
          onError(jqXHR);
        },
        beforeSend: function(jqxXHR) {
          onBeforeSend(jqxXHR);
        },
      },
      columns: [
        { text: "Id", datafield: "id", width: 50, editable: false },
        { text: "Country Id", datafield: "country_id", width: 100 },
        { text: "Country", datafield: "country" },
        { text: "Ranking ", datafield: "ranking", width: 100 },
        { text: "Slug", datafield: "slug", width: 200 },
      ],
      newRowPlaceholder: {
        country: "Placeholder",
        ranking: 0,
        slug: "Placeholder",
      },
    };
  },

  getCanale() {
    return {
      source: {
        datafields: [
          { name: "id", type: "number" },
          { name: "channel", type: "string" },
        ],
        datatype: "json",
        id: "id",
        url: endpointCanale + "/list",
        root: "data",
        async: false,
        loadError: function(jqXHR) {
          onError(jqXHR);
        },
        beforeSend: function(jqxXHR) {
          onBeforeSend(jqxXHR);
        },
      },
      columns: [
        { text: "Id", datafield: "id", width: 50, editable: false },
        { text: "Channel", datafield: "channel" },
      ],
      newRowPlaceholder: {
        channel: "Placeholder",
      },
    };
  },

  getStato() {
    return {
      source: {
        datafields: [
          { name: "id", type: "number" },
          { name: "status", type: "string" },
        ],
        datatype: "json",
        id: "id",
        url: endpointStato + "/list",
        root: "data",
        async: false,
        loadError: function(jqXHR) {
          onError(jqXHR);
        },
        beforeSend: function(jqxXHR) {
          onBeforeSend(jqxXHR);
        },
      },
      columns: [
        { text: "Id", datafield: "id", width: 50, editable: false },
        { text: "Status", datafield: "status" },
      ],
      newRowPlaceholder: {
        status: "Placeholder",
      },
    };
  },

  getPeriodo() {
    return {
      source: {
        datafields: [
          { name: "period_id", type: "number" },
          { name: "name", type: "string" },
          { name: "overtime", type: "bool" },
        ],
        datatype: "json",
        id: "period_id",
        url: endpointPeriodo + "/list",
        root: "data",
        async: false,
        loadError: function(jqXHR) {
          onError(jqXHR);
        },
        beforeSend: function(jqxXHR) {
          onBeforeSend(jqxXHR);
        },
      },
      columns: [
        { text: "Period Id", datafield: "period_id" },
        { text: "Descrizione", datafield: "name" },
        {
          text: "Overtime",
          datafield: "overtime",
          columntype: "checkbox",
          width: 150,
        },
      ],
      newRowPlaceholder: {
        period_id: 0,
        name: "Placeholder",
        overtime: 0,
      },
    };
  },

  getDescrizioneScommessa() {
    return {
      source: {
        datafields: [
          { name: "market_id", type: "number" },
          { name: "name", type: "string" },
        ],
        datatype: "json",
        id: "market_id",
        url: endpointDescrizioneScommessa + "/list",
        root: "data",
        async: false,
        loadError: function(jqXHR) {
          onError(jqXHR);
        },
        beforeSend: function(jqxXHR) {
          onBeforeSend(jqxXHR);
        },
      },
      columns: [
        { text: "Market Id", datafield: "market_id" },
        { text: "Descrizione", datafield: "name" },
      ],
      newRowPlaceholder: {
        market_id: 0,
        name: "Placeholder",
      },
    };
  },

  getDescrizioneEsito(marketAdapter) {
    return {
      source: {
        datafields: [
          { name: "id", type: "number" },
          { name: "market_id", type: "number" },
          {
            name: "market",
            value: "market_id",
            values: {
              source: marketAdapter.records,
              value: "market_id",
              name: "name",
            },
          },
          { name: "number", type: "number" },
          { name: "name", type: "string" },
          { name: "name_short", type: "string" },
        ],
        datatype: "json",
        id: "id",
        url: endpointDescrizioneEsito + "/list",
        root: "data",
        async: false,
        loadError: function(jqXHR) {
          onError(jqXHR);
        },
        beforeSend: function(jqxXHR) {
          onBeforeSend(jqxXHR);
        },
      },
      columns: [
        { text: "Id", datafield: "id", width: 50, editable: false },
        //{ text: "Market Id", datafield: "market_id" },
        {
          text: "Scommessa",
          datafield: "market_id",
          displayfield: "market",
          columntype: "dropdownlist",
          createeditor: (row, value, editor) => {
            editor.jqxDropDownList({
              source: marketAdapter,
              displayMember: "name",
              valueMember: "market_id",
            });
          },
        },
        { text: "Numero", datafield: "number" },
        { text: "Descrizione", datafield: "name" },
        { text: "Descrizione breve", datafield: "name_short" },
      ],
      newRowPlaceholder: {
        market_id: 0,
        number: 0,
        name: "Placeholder",
        name_short: "Ph",
      },
    };
  },

  getMappingNazione(providerAdapter) {
    return {
      source: {
        datafields: [
          { name: "id", type: "number" },
          { name: "provider_id", type: "number" },
          {
            name: "provider",
            value: "provider_id",
            values: {
              source: providerAdapter.records,
              value: "id",
              name: "provider",
            },
          },
          { name: "ejoy_country_id", type: "number" },
          {
            name: "country_name",
            type: "string",
          },
          { name: "provider_country_id", type: "string" },
        ],
        datatype: "json",
        id: "id",
        url: endpointMappingNazione + "/list",
        root: "data",
        async: false,
        loadError: function(jqXHR) {
          onError(jqXHR);
        },
        beforeSend: function(jqxXHR) {
          onBeforeSend(jqxXHR);
        },
      },
      columns: [
        { text: "Id", datafield: "id", width: 50, editable: false },
        {
          text: "Provider",
          datafield: "provider_id",
          displayfield: "provider",
          columntype: "dropdownlist",
          createeditor: (row, value, editor) => {
            editor.jqxDropDownList({
              source: providerAdapter,
              displayMember: "provider",
              valueMember: "id",
            });
          },
        },
        { text: "Ejoy Country Id", datafield: "ejoy_country_id", width: 200 },
        { text: "Nazione", datafield: "country_name", editable: false },
        {
          text: "Provider Country Id",
          datafield: "provider_country_id",
          width: 200,
        },
      ],
      newRowPlaceholder: {
        provider_id: 0,
        ejoy_country_id: 0,
        provider_country_id: 0,
      },
    };
  },

  getMappingDisciplina(providerAdapter) {
    return {
      source: {
        datafields: [
          { name: "id", type: "number" },
          { name: "provider_id", type: "number" },
          {
            name: "provider",
            value: "provider_id",
            values: {
              source: providerAdapter.records,
              value: "id",
              name: "provider",
            },
          },
          { name: "sport_name", type: "string" },
          { name: "ejoy_sport_id", type: "number" },
          { name: "provider_sport_id", type: "string" },
        ],
        datatype: "json",
        id: "id",
        url: endpointMappingDisciplina + "/list",
        root: "data",
        async: false,
        loadError: function(jqXHR) {
          onError(jqXHR);
        },
        beforeSend: function(jqxXHR) {
          onBeforeSend(jqxXHR);
        },
      },
      columns: [
        { text: "Id", datafield: "id", width: 50, editable: false },
        {
          text: "Provider",
          datafield: "provider_id",
          displayfield: "provider",
          columntype: "dropdownlist",
          createeditor: (row, value, editor) => {
            editor.jqxDropDownList({
              source: providerAdapter,
              displayMember: "provider",
              valueMember: "id",
            });
          },
        },
        { text: "Sport", datafield: "sport_name", editable: false },
        { text: "Ejoy Sport Id", datafield: "ejoy_sport_id", width: 200 },
        {
          text: "Provider Sport Id",
          datafield: "provider_sport_id",
          width: 200,
        },
      ],
      newRowPlaceholder: {
        provider_id: 0,
        ejoy_sport_id: 0,
        provider_sport_id: 0,
      },
    };
  },

  getMappingManifestazione(providerAdapter) {
    return {
      source: {
        datafields: [
          { name: "id", type: "number" },
          { name: "ejoy_tournament_id", type: "number" },
          {
            name: "tournament_name",
            type: "string",
          },
          { name: "provider_id", type: "number" },
          {
            name: "provider",
            value: "provider_id",
            values: {
              source: providerAdapter.records,
              value: "id",
              name: "provider",
            },
          },
          { name: "provider_tournament_id", type: "number" },
        ],
        datatype: "json",
        id: "id",
        url: endpointMappingManifestazione + "/list",
        root: "data",
        async: false,
        loadError: function(jqXHR) {
          onError(jqXHR);
        },
        beforeSend: function(jqxXHR) {
          onBeforeSend(jqxXHR);
        },
      },
      columns: [
        { text: "Id", datafield: "id", width: 50, editable: false },
        {
          text: "Ejoy Manifestazione Id",
          datafield: "ejoy_tournament_id",
          width: 200,
        },
        {
          text: "Manifestazione",
          datafield: "tournament_name",
          editable: false,
        },
        {
          text: "Provider",
          datafield: "provider_id",
          displayfield: "provider",
          columntype: "dropdownlist",
          createeditor: (row, value, editor) => {
            editor.jqxDropDownList({
              source: providerAdapter,
              displayMember: "provider",
              valueMember: "id",
            });
          },
        },
        {
          text: "Provider Manifestazione Id",
          datafield: "provider_tournament_id",
          width: 200,
        },
      ],
      newRowPlaceholder: {
        provider_id: 0,
        ejoy_tournament_id: 0,
        provider_tournament_id: 0,
      },
    };
  },

  getMappingAvvenimento(providerAdapter) {
    return {
      source: {
        datafields: [
          { name: "id", type: "number" },
          { name: "ejoy_event_id", type: "number" },
          {
            name: "event_name",
            type: "string",
          },
          { name: "provider_id", type: "number" },
          {
            name: "provider",
            value: "provider_id",
            values: {
              source: providerAdapter.records,
              value: "id",
              name: "provider",
            },
          },
          { name: "provider_event_id", type: "number" },
          { name: "linked", type: "bool" },
        ],
        datatype: "json",
        id: "id",
        url: endpointMappingAvvenimento + "/list",
        root: "data",
        async: false,
        loadError: function(jqXHR) {
          onError(jqXHR);
        },
        beforeSend: function(jqxXHR) {
          onBeforeSend(jqxXHR);
        },
      },
      columns: [
        { text: "Id", datafield: "id", width: 50, editable: false },
        { text: "Ejoy Avvenimento Id", datafield: "ejoy_event_id", width: 200 },
        {
          text: "Avvenimento",
          datafield: "event_name",
          editable: false,
        },
        {
          text: "Provider",
          datafield: "provider_id",
          displayfield: "provider",
          columntype: "dropdownlist",
          createeditor: (row, value, editor) => {
            editor.jqxDropDownList({
              source: providerAdapter,
              displayMember: "provider",
              valueMember: "id",
            });
          },
        },
        {
          text: "Provider Avvenimento Id",
          datafield: "provider_event_id",
          width: 200,
        },
        {
          text: "Linked",
          datafield: "linked",
          columntype: "checkbox",
          width: 50,
        },
      ],
      newRowPlaceholder: {
        provider_id: 0,
        ejoy_event_id: 0,
        provider_event_id: 0,
      },
    };
  },

  getMappingScommessa(providerAdapter) {
    return {
      source: {
        datafields: [
          { name: "id", type: "number" },
          { name: "provider_id", type: "number" },
          {
            name: "provider_name",
            value: "provider_id",
            values: {
              source: providerAdapter.records,
              value: "id",
              name: "provider",
            },
          },

          { name: "ejoy_market_id", type: "number" },
          {
            name: "market_name",
            type: "string",
          },
          { name: "outcome_name", type: "string" },
          { name: "ejoy_outcome_number", type: "number" },
          { name: "provider_market_id", type: "string" },
          { name: "provider_outcome_number", type: "number" },
        ],
        datatype: "json",
        id: "id",
        url: endpointMappingScommessa + "/list",
        root: "data",
        async: false,
        loadError: function(jqXHR) {
          onError(jqXHR);
        },
        beforeSend: function(jqxXHR) {
          onBeforeSend(jqxXHR);
        },
      },
      columns: [
        { text: "Id", datafield: "id", width: 50, editable: false },
        {
          text: "Provider",
          datafield: "provider_id",
          displayfield: "provider_name",
          columntype: "dropdownlist",
          createeditor: (row, value, editor) => {
            editor.jqxDropDownList({
              source: providerAdapter,
              displayMember: "provider",
              valueMember: "id",
            });
          },
        },

        { text: "Ejoy Scommessa Id", datafield: "ejoy_market_id", width: 200 },
        { text: "Scommessa", datafield: "market_name", editable: false },
        {
          text: "Ejoy Numero Esito",
          datafield: "ejoy_outcome_number",
          width: 200,
        },
        {
          text: "Esito",
          datafield: "outcome_name",
          editable: false,
        },
        {
          text: "Provider Scommessa Id",
          datafield: "provider_market_id",
          width: 200,
        },
        {
          text: "Ejoy Numero Esito",
          datafield: "provider_outcome_number",
          width: 200,
        },
      ],
      newRowPlaceholder: {
        provider_id: 0,
        ejoy_market_id: 0,
        provider_market_id: 0,
        ejoy_outcome_number: 0,
        provider_outcome_number: 0,
      },
    };
  },
  getMappingPeriodo(providerAdapter) {
    return {
      source: {
        datafields: [
          { name: "id", type: "number" },
          { name: "provider_id", type: "number" },
          {
            name: "provider",
            value: "provider_id",
            values: {
              source: providerAdapter.records,
              value: "id",
              name: "provider",
            },
          },
          { name: "period_name", type: "string" },
          { name: "ejoy_period_id", type: "number" },
          { name: "provider_period_id", type: "string" },
        ],
        datatype: "json",
        id: "id",
        url: endpointMappingPeriodo + "/list",
        root: "data",
        async: false,
        loadError: function(jqXHR) {
          onError(jqXHR);
        },
        beforeSend: function(jqxXHR) {
          onBeforeSend(jqxXHR);
        },
      },
      columns: [
        { text: "Id", datafield: "id", width: 50, editable: false },
        {
          text: "Provider",
          datafield: "provider_id",
          displayfield: "provider",
          columntype: "dropdownlist",
          createeditor: (row, value, editor) => {
            editor.jqxDropDownList({
              source: providerAdapter,
              displayMember: "provider",
              valueMember: "id",
            });
          },
        },
        { text: "Nome periodo", datafield: "period_name" },
        { text: "Ejoy Period Id", datafield: "ejoy_period_id", width: 200 },
        {
          text: "Provider Period Id",
          datafield: "provider_period_id",
          width: 200,
        },
      ],
      newRowPlaceholder: {
        provider_id: 0,
        ejoy_period_id: 0,
        provider_period_id: 0,
      },
    };
  },

  getMacchinaStreaming() {
    return {
      source: {
        datafields: [
          { name: "id", type: "number" },
          { name: "channel_id", type: "number" },
          { name: "active", type: "number" },
          { name: "name", type: "string" },
          { name: "host", type: "string" },
          { name: "port", type: "number" },
          { name: "api_key", type: "string" },
          { name: "status", type: "string" },
        ],
        datatype: "json",
        id: "id",
        url: endpointMacchinaStreaming + "/list",
        root: "data",
        async: false,
        loadError: function(jqXHR) {
          onError(jqXHR);
        },
        beforeSend: function(jqxXHR) {
          onBeforeSend(jqxXHR);
        },
      },
      columns: [
        { text: "Id", datafield: "id", width: 50, editable: false },
        { text: "Canale", datafield: "channel_id" },
        { text: "Attivo", datafield: "active", width: 200 },
        { text: "Nome", datafield: "name", width: 200 },
        { text: "Host", datafield: "host", width: 200 },
        { text: "Porta", datafield: "port", width: 200 },
        { text: "Api Key", datafield: "api_key", width: 200 },
        { text: "Stato", datafield: "status" },
      ],
      newRowPlaceholder: {
        name: "placeholder",
        host: "placeholder",
        port: 0,
        status: "{}",
      },
    };
  },

  getImpostazioniEvento(providerAdapter) {
    return {
      source: {
        datafields: [
          { name: "id", type: "number" },
          { name: "provider_id", type: "number" },
          {
            name: "provider",
            value: "provider_id",
            values: {
              source: providerAdapter.records,
              value: "id",
              name: "provider",
            },
          },
          { name: "tournament_id", type: "number" },
          { name: "start_time", type: "time" },
          { name: "end_time", type: "time" },
          { name: "day_fix", type: "number" },
        ],
        datatype: "json",
        id: "id",
        url: endpointImpostazioniEvento + "/list",
        root: "data",
        async: false,
        loadError: function(jqXHR) {
          onError(jqXHR);
        },
        beforeSend: function(jqxXHR) {
          onBeforeSend(jqxXHR);
        },
      },
      columns: [
        { text: "Id", datafield: "id", width: 50, editable: false },
        {
          text: "Provider",
          datafield: "provider_id",
          displayfield: "provider",
          columntype: "dropdownlist",
          createeditor: (row, value, editor) => {
            editor.jqxDropDownList({
              source: providerAdapter,
              displayMember: "provider",
              valueMember: "id",
            });
          },
          
        },
        { text: "Manifestazione", datafield: "tournament_id", width: 200, editable: false },
        { text: "Inizio", datafield: "start_time", width: 250 },
        { text: "Fine", datafield: "end_time", width: 250 },
        { text: "Fix giorno", datafield: "day_fix", width: 200 },
      ],
      newRowPlaceholder: {
        provider_id: 0,
      },
    };
  },
};
