import ajaxCrud from "./ajaxCrud";
import { endpointCanale } from "../../constants";

export default {
  getAll(cb = null) {
    ajaxCrud.getAll(endpointCanale, cb);
  },

  get(id, cb) {
    ajaxCrud.get(endpointCanale, id, cb);
  },

  update(id, data = {}, cb) {
    ajaxCrud.update(endpointCanale, id, data, cb);
  },

  create(data = {}, cb) {
    ajaxCrud.create(endpointCanale, data, cb);
  },

  delete(id, cb) {
    ajaxCrud.delete(endpointCanale, id, cb);
  },

  massDelete(ids, cb) {
    ajaxCrud.massDelete(endpointCanale, ids, cb);
  },

  massUpdate(rows, cb) {
    ajaxCrud.massUpdate(endpointCanale, rows, cb);
  },
};
