<template>
  <div>
    <h3 class="mb-4">Gestione avvenimenti</h3>
    <div id="avvenimentoGrid"></div>
  </div>
</template>
<script>
import Vue from "vue";
import EditableGrid from "@/components/EditableGrid";
import apiAvvenimenti from "../services/avvenimento";
import jqxgridDefinition from "../helpers/jqxgridDefinition";

let source = {};
let columns = [];
let instanceEventGrid = null;
let newRowPlaceholder = null;

export default {
  beforeCreate() {
    var sportSource = jqxgridDefinition.getDisciplina().source;
    //eslint-disable-next-line
    let sportAdapter = new jqx.dataAdapter(sportSource, { autoBind: true });

    var providerSource = jqxgridDefinition.getFornitore().source;
    //eslint-disable-next-line
    let providerAdapter = new jqx.dataAdapter(providerSource, {
      autoBind: true,
    });

    var channelSource = jqxgridDefinition.getCanale().source;
    //eslint-disable-next-line
    let channelAdapter = new jqx.dataAdapter(channelSource, {
      autoBind: true,
    });

    var statusSource = jqxgridDefinition.getStato().source;
    //eslint-disable-next-line
    let statusAdapter = new jqx.dataAdapter(statusSource, {
      autoBind: true,
    });

    var countrySource = jqxgridDefinition.getNazione().source;
    //eslint-disable-next-line
    let countryAdapter = new jqx.dataAdapter(countrySource, { autoBind: true });

    var tournamentSource =
      jqxgridDefinition.getManifestazione(countryAdapter).source;
    //eslint-disable-next-line
    let tournamentAdapter = new jqx.dataAdapter(tournamentSource, {
      autoBind: true,
    });

    var definition = jqxgridDefinition.getAvvenimento(
      sportAdapter,
      providerAdapter,
      channelAdapter,
      statusAdapter,
      tournamentAdapter
    );
    source = definition.source;
    columns = definition.columns;
    newRowPlaceholder = definition.newRowPlaceholder;
  },

  mounted() {
    var AvvenimentoGrid = Vue.extend(EditableGrid);
    instanceEventGrid = new AvvenimentoGrid({
      propsData: {
        columns: columns,
        source: source,
      },
    });
    instanceEventGrid.$on("createRow", this.createAvvenimento);
    instanceEventGrid.$on("updateRows", this.updateAvvenimenti);
    instanceEventGrid.$on("deleteRows", this.deleteAvvenimenti);

    var newDiv = document.createElement("div");
    newDiv.setAttribute("id", "avvenimentoDynamicGrid");
    document.getElementById("avvenimentoGrid").appendChild(newDiv);
    instanceEventGrid.$mount("#avvenimentoDynamicGrid");
  },
  beforeDestroy() {
    instanceEventGrid.$destroy();
  },
  methods: {
    createAvvenimento() {
      apiAvvenimenti.create(newRowPlaceholder, () => {
        instanceEventGrid.updateBoundData("data");
      });
    },
    updateAvvenimento(id, row) {
      apiAvvenimenti.update(id, row, () => {
        instanceEventGrid.updateBoundData("data");
      });
    },
    updateAvvenimenti(rows) {
      apiAvvenimenti.massUpdate(rows, () => {
        instanceEventGrid.updateBoundData("data");
      });
    },
    deleteAvvenimenti(ids) {
      apiAvvenimenti.massDelete(ids, () => {
        instanceEventGrid.updateBoundData("data");
      });
    },
  },
};
</script>