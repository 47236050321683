<template>
  <div>
    <h3 class="mb-4">Anagrafica utenti</h3>
    <b-button size="sm" v-b-modal.modal-user @click="modalType = 'create'">
      Crea nuovo utente
    </b-button>
    <b-table-simple hover responsive>
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>Nome</b-th>
          <b-th>Email</b-th>
          <b-th>Email verificata il</b-th>
          <b-th>Api token</b-th>
          <b-th>Remember token</b-th>
          <b-th>Data creazione</b-th>
          <b-th>Data ultima modifica</b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="user in users" :key="user.id">
          <b-td>{{ user.name }}</b-td>
          <b-td>{{ user.email }}</b-td>
          <b-td>{{ user.email_verified_at }}</b-td>
          <b-td>{{ user.api_token }}</b-td>
          <b-td>{{ user.remember_token }}</b-td>
          <b-td>{{ user.created_at }}</b-td>
          <b-td>{{ user.updated_at }}</b-td>
          <b-td>
            <b-button
              size="sm"
              class="m-1"
              v-b-modal.modal-user
              @click="[(selectedUser = user), (modalType = 'edit')]"
            >
              Modifica
            </b-button>
            <b-button size="sm" class="m-1" @click="generateApiToken(user)">
              Genera api token
            </b-button>
            <b-button size="sm" class="m-1" @click="deleteUser(user)">
              Elimina utente
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <b-modal
      id="modal-user"
      ref="modal"
      :title="modalType == 'edit' ? 'Modifica' : 'Crea nuovo utente'"
      @show="setModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group label="Name" label-for="name" :state="nameState">
          <b-form-input
            id="name"
            v-model="name"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Email" label-for="email" :state="emailState">
          <b-form-input
            id="email"
            v-model="email"
            :state="emailState"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Password"
          label-for="password"
          :state="passwordState"
        >
          <b-form-input
            id="password"
            type="password"
            v-model="password"
            :state="passwordState"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Ripeti password"
          label-for="password2"
          :state="passwordState"
        >
          <b-form-input
            id="password2"
            type="password"
            v-model="password2"
            :state="passwordState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>
<script>
import apiUtente from "../services/utente";

//let newRowPlaceholder = null;

export default {
  data() {
    return {
      users: {},
      selectedUser: {},
      modalType: "",
      name: "",
      nameState: null,
      email: "",
      emailState: null,
      password: "",
      password2: "",
      passwordState: null,
    };
  },
  mounted() {
    apiUtente.getAll((res) => {
      this.users = res;
    });
  },

  methods: {
    checkFormValidity() {
      this.nameState = this.validate("name", this.name);
      this.emailState = this.validate("email", this.email);
      this.passwordState = this.validate(
        "password",
        this.password,
        this.password2
      );
      let valid = this.nameState && this.emailState && this.passwordState;
      return valid;
    },
    validate(type, value, value2 = null) {
      switch (type) {
        case "name":
          return value.length > 3;
        case "email":
          var re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(value);
        case "password":
          return value == value2;
      }
    },
    setModal() {
      if (Object.keys(this.selectedUser).length > 0) {
        this.name = this.selectedUser.name;
        this.email = this.selectedUser.email;
      } else this.resetModal();
    },
    resetModal() {
      this.selectedUser = {};
      this.name = "";
      this.email = "";
      this.password = "";
      this.password2 = "";

      this.nameState = null;
      this.emailState = null;
      this.passwordState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      console.log(this.selectedUser);

      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      if (this.modalType == "edit") {
        let editUser = {};
        if (this.selectedUser.name != this.name) editUser.name = this.name;
        if (this.selectedUser.email != this.email) editUser.email = this.email;
        if (this.password != "") editUser.password = this.password;

        apiUtente.update(this.selectedUser.id, editUser, () => {
          location.reload();
        });
      } else {
        let newUser = {
          name: this.name,
          email: this.email,
          password: this.password,
        };

        apiUtente.create(newUser, () => {
          location.reload();
        });
      }

      // Hide the modal manually
      this.$bvModal.hide("modal-prevent-closing");
    },
    generateApiToken(user) {
      this.$bvModal
        .msgBoxConfirm(
          "Sei sicuro di voler generare un nuovo API TOKEN per l'utente " +
            user.name +
            "?",
          {
            title: "Conferma",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "GENERA",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value)
            apiUtente.update(user.id, { api_token: user.api_token }, () => {
              location.reload();
            });
        });
    },
    deleteUser(user) {
      this.$bvModal
        .msgBoxConfirm(
          "Sei sicuro di voler eliminare l'utente " + user.name + "?",
          {
            title: "Conferma",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "ELIMINA",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value)
            apiUtente.delete(user.id, () => {
              location.reload();
            });
        });
    },

    /*createUtente() {
      apiUtente.create(newRowPlaceholder, () => {});
    },
    updateUtente(id, row) {
      apiUtente.update(id, row, () => {});
    },
    deleteUtenti(ids) {
      apiUtente.massDelete(ids, () => {});
    },*/
  },
};
</script>