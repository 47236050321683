<template>
  <div>
    <h3 class="mb-4">Gestione periodi</h3>
    <div id="periodoGrid"></div>
  </div>
</template>
<script>
import Vue from "vue";
import EditableGrid from "@/components/EditableGrid";
import apiPeriodo from "../services/periodo";
import jqxgridDefinition from "../helpers/jqxgridDefinition";

let source = {};
let columns = [];
let instancePeriodGrid = null;
let newRowPlaceholder = null;

export default {
  beforeCreate() {
    source = jqxgridDefinition.getPeriodo().source;
    columns = jqxgridDefinition.getPeriodo().columns;
    newRowPlaceholder = jqxgridDefinition.getPeriodo().newRowPlaceholder;
  },
  mounted() {
    var PeriodoGrid = Vue.extend(EditableGrid);
    instancePeriodGrid = new PeriodoGrid({
      propsData: {
        columns: columns,
        source: source,
      },
    });
    instancePeriodGrid.$on("createRow", this.createPeriodo);
    instancePeriodGrid.$on("updateRows", this.updatePeriodi);
    instancePeriodGrid.$on("deleteRows", this.deletePeriodi);

    var newDiv = document.createElement("div");
    newDiv.setAttribute("id", "periodoDynamicGrid");
    document.getElementById("periodoGrid").appendChild(newDiv);
    instancePeriodGrid.$mount("#periodoDynamicGrid");
  },

  beforeDestroy() {
    instancePeriodGrid.$destroy();
  },

  methods: {
    createPeriodo() {
      apiPeriodo.create(newRowPlaceholder, () => {
        instancePeriodGrid.updateBoundData("data");
      });
    },
    updatePeriodo(id, row) {
      apiPeriodo.update(id, row, () => {
        instancePeriodGrid.updateBoundData("data");
      });
    },
    updatePeriodi(rows) {
      apiPeriodo.massUpdate(rows, () => {
        instancePeriodGrid.updateBoundData("data");
      });
    },
    deletePeriodi(ids) {
      apiPeriodo.massDelete(ids, () => {
        instancePeriodGrid.updateBoundData("data");
      });
    },
  },
};
</script>