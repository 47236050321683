import { BToast } from "bootstrap-vue";

export default {
  errorToast(message) {
    let bootStrapToaster = new BToast();
    bootStrapToaster.$bvToast.toast(message, {
      title: "Si è verificato un errore",
      toaster: "b-toaster-top-right",
      solid: false,
      appendToast: true,
      toastClass: "showing",
      noCloseButton: true,
      variant: "danger",
      autoHideDelay: 5000,
    });
  },

  successToast(message) {
    let bootStrapToaster = new BToast();
    bootStrapToaster.$bvToast.toast(message, {
      title: "Operazione eseguita con successo",
      toaster: "b-toaster-top-right",
      solid: false,
      appendToast: true,
      toastClass: "showing",
      noCloseButton: true,
      variant: "success",
      autoHideDelay: 5000,
    });
  },
};
