<template>
  <div>
    <h3 class="mb-4">Gestione discipline</h3>
    <div id="disciplinaGrid"></div>
  </div>
</template>
<script>
import Vue from "vue";
import EditableGrid from "@/components/EditableGrid";
import apiDisciplina from "../services/disciplina";
//import { endpointDisciplina } from "../../constants";
import jqxgridDefinition from "../helpers/jqxgridDefinition";

let source = {};
let columns = [];
let instanceSportGrid = null;
let newRowPlaceholder = null;

export default {
  beforeCreate() {
    source = jqxgridDefinition.getDisciplina().source;
    columns = jqxgridDefinition.getDisciplina().columns;
    newRowPlaceholder = jqxgridDefinition.getDisciplina().newRowPlaceholder;
  },
  mounted() {
    var DisciplinaGrid = Vue.extend(EditableGrid);
    instanceSportGrid = new DisciplinaGrid({
      propsData: {
        columns: columns,
        source: source,
      },
    });
    instanceSportGrid.$on("createRow", this.createDisciplina);
    instanceSportGrid.$on("updateRows", this.updateDiscipline);
    instanceSportGrid.$on("deleteRows", this.deleteDiscipline);

    var newDiv = document.createElement("div");
    newDiv.setAttribute("id", "disciplinaDynamicGrid");
    document.getElementById("disciplinaGrid").appendChild(newDiv);
    instanceSportGrid.$mount("#disciplinaDynamicGrid");
  },

  beforeDestroy() {
    instanceSportGrid.$destroy();
  },

  methods: {
    createDisciplina() {
      apiDisciplina.create(newRowPlaceholder, () => {
        instanceSportGrid.updateBoundData("data");
      });
    },
    updateDisciplina(id, row) {
      apiDisciplina.update(id, row, () => {
        instanceSportGrid.updateBoundData("data");
      });
    },
    updateDiscipline(rows) {
      apiDisciplina.massUpdate(rows, () => {
        instanceSportGrid.updateBoundData("data");
      });
    },
    deleteDiscipline(ids) {
      apiDisciplina.massDelete(ids, () => {
        instanceSportGrid.updateBoundData("data");
      });
    },
  },
};
</script>