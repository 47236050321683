import ajaxCrud from "./ajaxCrud";
import { endpointNazione } from "../../constants";

export default {
  getAll(cb = null) {
    ajaxCrud.getAll(endpointNazione, cb);
  },

  get(id, cb) {
    ajaxCrud.get(endpointNazione, id, cb);
  },

  update(id, data = {}, cb) {
    ajaxCrud.update(endpointNazione, id, data, cb);
  },

  create(data = {}, cb) {
    ajaxCrud.create(endpointNazione, data, cb);
  },

  delete(id, cb) {
    ajaxCrud.delete(endpointNazione, id, cb);
  },

  massDelete(ids, cb) {
    ajaxCrud.massDelete(endpointNazione, ids, cb);
  },

  massUpdate(rows, cb) {
    ajaxCrud.massUpdate(endpointNazione, rows, cb);
  },
};
