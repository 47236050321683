<template>
  <div>
    <h3 class="mb-4">Gestione stati</h3>
    <div id="statoGrid"></div>
  </div>
</template>
<script>
import Vue from "vue";
import EditableGrid from "@/components/EditableGrid";
import apiStato from "../services/stato";
import jqxgridDefinition from "../helpers/jqxgridDefinition";

let source = {};
let columns = [];
let instanceStatusGrid = null;
let newRowPlaceholder = null;

export default {
  beforeCreate() {
    source = jqxgridDefinition.getStato().source;
    columns = jqxgridDefinition.getStato().columns;
    newRowPlaceholder = jqxgridDefinition.getStato().newRowPlaceholder;
  },
  mounted() {
    var StatoGrid = Vue.extend(EditableGrid);
    instanceStatusGrid = new StatoGrid({
      propsData: {
        columns: columns,
        source: source,
      },
    });
    instanceStatusGrid.$on("createRow", this.createStato);
    instanceStatusGrid.$on("updateRows", this.updateStati);
    instanceStatusGrid.$on("deleteRows", this.deleteStati);

    var newDiv = document.createElement("div");
    newDiv.setAttribute("id", "statoDynamicGrid");
    document.getElementById("statoGrid").appendChild(newDiv);
    instanceStatusGrid.$mount("#statoDynamicGrid");
  },

  beforeDestroy() {
    instanceStatusGrid.$destroy();
  },

  methods: {
    createStato() {
      apiStato.create(newRowPlaceholder, () => {
        instanceStatusGrid.updateBoundData("data");
      });
    },
    updateStato(id, row) {
      apiStato.update(id, row, () => {
        instanceStatusGrid.updateBoundData("data");
      });
    },
    updateStati(rows) {
      apiStato.massUpdate(rows, () => {
        instanceStatusGrid.updateBoundData("data");
      });
    },
    deleteStati(ids) {
      apiStato.massDelete(ids, () => {
        instanceStatusGrid.updateBoundData("data");
      });
    },
  },
};
</script>