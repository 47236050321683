<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
export default {
  name: "App",
  computed: {
    layout() {
      return this.$route.meta.layout || "Default";
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/variables";
body {
  background-color: $body-bg;
}
</style>
