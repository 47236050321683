import ajaxCrud from "./ajaxCrud";
import { endpointStato } from "../../constants";

export default {
  getAll(cb = null) {
    ajaxCrud.getAll(endpointStato, cb);
  },

  get(id, cb) {
    ajaxCrud.get(endpointStato, id, cb);
  },

  update(id, data = {}, cb) {
    ajaxCrud.update(endpointStato, id, data, cb);
  },

  create(data = {}, cb) {
    ajaxCrud.create(endpointStato, data, cb);
  },

  delete(id, cb) {
    ajaxCrud.delete(endpointStato, id, cb);
  },

  massDelete(ids, cb) {
    ajaxCrud.massDelete(endpointStato, ids, cb);
  },

  massUpdate(rows, cb) {
    ajaxCrud.massUpdate(endpointStato, rows, cb);
  },
};
