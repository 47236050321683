<template>
  <div>
    <h3 class="mb-4">Gestione canali</h3>
    <div id="canaleGrid"></div>
  </div>
</template>
<script>
import Vue from "vue";
import EditableGrid from "@/components/EditableGrid";
import apiCanale from "../services/canale";
import jqxgridDefinition from "../helpers/jqxgridDefinition";

let source = {};
let columns = [];
let instanceChannelGrid = null;
let newRowPlaceholder = null;

export default {
  beforeCreate() {
    source = jqxgridDefinition.getCanale().source;
    columns = jqxgridDefinition.getCanale().columns;
    newRowPlaceholder = jqxgridDefinition.getCanale().newRowPlaceholder;
  },
  mounted() {
    var CanaleGrid = Vue.extend(EditableGrid);
    instanceChannelGrid = new CanaleGrid({
      propsData: {
        columns: columns,
        source: source,
      },
    });
    instanceChannelGrid.$on("createRow", this.createCanale);
    instanceChannelGrid.$on("updateRows", this.updateCanali);
    instanceChannelGrid.$on("deleteRows", this.deleteCanali);

    var newDiv = document.createElement("div");
    newDiv.setAttribute("id", "canaleDynamicGrid");
    document.getElementById("canaleGrid").appendChild(newDiv);
    instanceChannelGrid.$mount("#canaleDynamicGrid");
    //instanceChannelGrid.$mount("#canaleGrid");
  },

  beforeDestroy() {
    instanceChannelGrid.$destroy();
  },

  methods: {
    createCanale() {
      apiCanale.create(newRowPlaceholder, () => {
        instanceChannelGrid.updateBoundData("data");
      });
    },
    updateCanale(id, row) {
      apiCanale.update(id, row, () => {
        instanceChannelGrid.updateBoundData("data");
      });
    },
    updateCanali(rows) {
      apiCanale.massUpdate(rows, () => {
        instanceChannelGrid.updateBoundData("data");
      });
    },
    deleteCanali(ids) {
      apiCanale.massDelete(ids, () => {
        instanceChannelGrid.updateBoundData("data");
      });
    },
  },
};
</script>