<template>
  <div>
    <h3 class="mb-4">Gestione Macchine Streaming</h3>
    <div id="MacchinaStreamingGrid"></div>
  </div>
</template>
<script>
import Vue from "vue";
import EditableGrid from "@/components/EditableGrid";
import apiMacchineStreaming from "../services/macchinaStreaming";
import jqxgridDefinition from "../helpers/jqxgridDefinition";

let source = {};
let columns = [];
let instanceStreamingMachineGrid = null;
let newRowPlaceholder = null;

export default {
  beforeCreate() {
    source = jqxgridDefinition.getMacchinaStreaming().source;
    columns = jqxgridDefinition.getMacchinaStreaming().columns;
    newRowPlaceholder = jqxgridDefinition.getMacchinaStreaming().newRowPlaceholder;
  },
  mounted() {
    var MacchinaStreamingGrid = Vue.extend(EditableGrid);
    instanceStreamingMachineGrid = new MacchinaStreamingGrid({
      propsData: {
        columns: columns,
        source: source,
      },
    });
    instanceStreamingMachineGrid.$on("createRow", this.createMacchinaStreaming);
    instanceStreamingMachineGrid.$on("updateRows", this.updateMacchineStreaming);
    instanceStreamingMachineGrid.$on("deleteRows", this.deleteMacchineStreaming);

    var newDiv = document.createElement("div");
    newDiv.setAttribute("id", "MacchinaStreamingDynamicGrid");
    document.getElementById("MacchinaStreamingGrid").appendChild(newDiv);
    instanceStreamingMachineGrid.$mount("#MacchinaStreamingDynamicGrid");
  },
  beforeDestroy() {
    instanceStreamingMachineGrid.$destroy();
  },
  methods: {
    createMacchinaStreaming() {
      apiMacchineStreaming.create(newRowPlaceholder, () => {
        instanceStreamingMachineGrid.updateBoundData("data");
      });
    },
    updateMacchinaStreaming(id, row) {
      apiMacchineStreaming.update(id, row, () => {
        instanceStreamingMachineGrid.updateBoundData("data");
      });
    },
    updateMacchineStreaming(rows) {
      apiMacchineStreaming.massUpdate(rows, () => {
        instanceStreamingMachineGrid.updateBoundData("data");
      });
    },
    deleteMacchineStreaming(ids) {
      apiMacchineStreaming.massDelete(ids, () => {
        instanceStreamingMachineGrid.updateBoundData("data");
      });
    },
  },
};
</script>