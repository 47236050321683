<template>
  <div>
    <JqxGrid
      ref="grid"
      :width="'100%'"
      :autoheight="true"
      :source="dataAdapter"
      :columns="columns"
      :columnsresize="true"
      :filterable="true"
      :showfilterrow="true"
      :sortable="true"
      :editable="true"
      :enabletooltips="true"
      :showtoolbar="true"
      :selectionmode="'checkbox'"
      :rendertoolbar="rendertoolbar"
      :pageable="true"
      :pagesize="20"
      @cellendedit="cellEndEditEvent($event)"
    >
    </JqxGrid>

    <!-- jwxWindow
      @close="onCloseWindowSaveChanges($event)"
      @open="onOpenWindowSaveChanges($event)"
    -->
    <JqxWindow
      ref="windowMassUpdate"
      :width="280"
      :height="200"
      :isModal="true"
      :modalOpacity="0.3"
      :position="{ x: windowsPositionX, y: 200 }"
      :okButton="'.doMassUpdate'"
      :cancelButton="'.cancelMassUpdate'"
      :resizable="false"
      :autoOpen="false"
    >
      <div>Salva le modifiche</div>
      <div>
        <div>Sei sicuro di voler salvare le modifiche?</div>
        <div style="float: right; margin-top: 15px">
          <JqxButton
            class="doMassUpdate"
            style="display: inline-block; margin-right: 10px"
            :width="80"
            @click="sendMassUpdate()"
          >
            Sì
          </JqxButton>
          <JqxButton
            class="cancelMassUpdate"
            style="display: inline-block"
            :width="80"
          >
            Annulla
          </JqxButton>
        </div>
      </div>
    </JqxWindow>

    <JqxWindow
      ref="windowMassDelete"
      :width="280"
      :height="200"
      :isModal="true"
      :modalOpacity="0.3"
      :position="{ x: windowsPositionX, y: 200 }"
      :okButton="'.doMassDelete'"
      :cancelButton="'.cancelMassDelete'"
      :resizable="false"
      :autoOpen="false"
    >
      <div>Comferma eliminazione massiva</div>
      <div>
        <div>Sei sicuro di voler eliminare i record selezionati?</div>
        <div style="float: right; margin-top: 15px">
          <JqxButton
            class="doMassDelete"
            style="display: inline-block; margin-right: 10px"
            :width="80"
            @click="sendMassDelete()"
          >
            Sì
          </JqxButton>
          <JqxButton
            class="cancelMassDelete"
            style="display: inline-block"
            :width="80"
          >
            Annulla
          </JqxButton>
        </div>
      </div>
    </JqxWindow>
  </div>
</template>
<script>
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid.vue";
import JqxWindow from "jqwidgets-scripts/jqwidgets-vue/vue_jqxwindow.vue";
import JqxButton from "jqwidgets-scripts/jqwidgets-vue/vue_jqxbuttons.vue";
import $ from "jquery";

export default {
  components: {
    JqxGrid,
    JqxWindow,
    JqxButton,
  },
  props: ["columns", "source"],
  computed: {
    windowsPositionX: () => window.innerWidth / 2 + "px",
  },
  data: function () {
    return {
      //eslint-disable-next-line
      dataAdapter: new jqx.dataAdapter(this.source),
      editedRows: {},
    };
  },
  beforeDestroy() {
    this.$refs.grid.$destroy();
    $(".jqx-menu-wrapper").remove();
  },
  methods: {
    onOpenWindowSaveChanges: function (event) {
      console.log(event);
    },
    onCloseWindowSaveChanges: function (event) {
      console.log(event);
    },
    sendMassDelete: function () {
      let selectedrowindexes = this.$refs.grid.getselectedrowindexes();
      let ids = [];
      selectedrowindexes.forEach((rowindex) => {
        ids.push(this.$refs.grid.getrowid(rowindex));
      });
      this.$emit("deleteRows", ids);
      this.$refs.grid.selectedrowindexes = [];
      this.$refs.windowMassDelete.close();
    },
    sendMassUpdate: function () {
      this.$emit("updateRows", this.editedRows);
      this.editedRows = {};
      this.$refs.windowMassUpdate.close();
    },
    clearFiltering: function () {
      this.$refs.grid.clearfilters();
    },
    rendertoolbar: function (toolbar) {
      this.$refs.windowMassUpdate.close();
      let container = document.createElement("div");
      container.style.margin = "5px";
      let buttonContainer1 = document.createElement("div");
      let buttonContainer2 = document.createElement("div");
      let buttonContainer3 = document.createElement("div");
      let buttonContainer4 = document.createElement("div");
      buttonContainer1.id = "buttonContainer1";
      buttonContainer2.id = "buttonContainer2";
      buttonContainer3.id = "buttonContainer3";
      buttonContainer4.id = "buttonContainer4";
      buttonContainer1.style.cssText = "float: left";
      buttonContainer2.style.cssText = "float: left; margin-left: 15px";
      buttonContainer3.style.cssText = "float: left; margin-left: 15px";
      buttonContainer4.style.cssText = "float: right";
      container.appendChild(buttonContainer1);
      container.appendChild(buttonContainer2);
      container.appendChild(buttonContainer3);
      container.appendChild(buttonContainer4);
      toolbar[0].appendChild(container);
      //eslint-disable-next-line
      let addRowButton = jqwidgets.createInstance(
        "#buttonContainer1",
        "jqxButton",
        { width: 200, template: "success", value: "Aggiungi nuovo record" }
      );
      //eslint-disable-next-line
      let deleteRowButton = jqwidgets.createInstance(
        "#buttonContainer2",
        "jqxButton",
        { width: 200, template: "danger", value: "Elimina righe selezionate" }
      );
      //eslint-disable-next-line
      let saveRowsButton = jqwidgets.createInstance(
        "#buttonContainer3",
        "jqxButton",
        { width: 200, template: "warning", value: "Salva le modifiche" }
      );
      //eslint-disable-next-line
      let clearFilterButton = jqwidgets.createInstance(
        "#buttonContainer4",
        "jqxButton",
        { width: 200, template: "info", value: "Reimposta i filtri" }
      );
      addRowButton.addEventHandler("click", () => {
        this.$emit("createRow");
        this.$nextTick(this.$refs.grid.updatebounddata("data"));
      });
      deleteRowButton.addEventHandler("click", () => {
        this.$refs.windowMassDelete.open();
      });
      saveRowsButton.addEventHandler("click", () => {
        this.$refs.windowMassUpdate.open();
      });
      clearFilterButton.addEventHandler("click", () => {
        this.$refs.grid.clearfilters();
      });
    },

    cellEndEditEvent: function (event) {
      let value =
        typeof event.args.value === "object"
          ? event.args.value.value
          : event.args.value;
      let oldvalue =
        event.args.oldvalue && typeof event.args.oldvalue === "object"
          ? event.args.oldvalue.value
          : event.args.oldvalue;
      if (value != oldvalue) {
        var rowId = event.args.row.uid;
        if (!this.editedRows[rowId])
          this.editedRows[rowId] = {}; /*{uid: rowId};*/
        this.editedRows[rowId][event.args.datafield] = value;
        //this.$emit("updateRow", event.args.row.id, obj);
      }
    },

    updateBoundData(param) {
      this.$refs.grid.updatebounddata(param);
    },

    setSource(source, columns) {
      this.columns = columns;
      //eslint-disable-next-line
      this.dataAdapter = new jqx.dataAdapter(source);
      //this.$refs.grid.refresh();
    },
  },
};
</script>
<style>
</style>