<template>
  <div>
    <h3 class="mb-4">Gestione mappatura nazioni</h3>
    <div id="mappingNazioneGrid"></div>
  </div>
</template>
<script>
import Vue from "vue";
import EditableGrid from "@/components/EditableGrid";
import apiNazioni from "../../services/mapping/nazione";
import jqxgridDefinition from "../../helpers/jqxgridDefinition";

let source = {};
let columns = [];
let instanceMapCountryGrid = null;
let newRowPlaceholder = null;

export default {
  beforeCreate() {
    var providerSource = jqxgridDefinition.getFornitore().source;
    //eslint-disable-next-line
    let providerAdapter = new jqx.dataAdapter(providerSource, {
      autoBind: true,
    });
    var definition = jqxgridDefinition.getMappingNazione(providerAdapter);

    source = definition.source;
    columns = definition.columns;
    newRowPlaceholder = definition.newRowPlaceholder;
  },
  mounted() {
    var MapNazioneGrid = Vue.extend(EditableGrid);
    instanceMapCountryGrid = new MapNazioneGrid({
      propsData: {
        columns: columns,
        source: source,
      },
    });
    instanceMapCountryGrid.$on("createRow", this.createNazione);
    instanceMapCountryGrid.$on("updateRows", this.updateNazioni);
    instanceMapCountryGrid.$on("deleteRows", this.deleteNazioni);

    var newDiv = document.createElement("div");
    newDiv.setAttribute("id", "mappingNazioneDynamicGrid");
    document.getElementById("mappingNazioneGrid").appendChild(newDiv);
    instanceMapCountryGrid.$mount("#mappingNazioneDynamicGrid");
  },
  beforeDestroy() {
    instanceMapCountryGrid.$destroy();
  },
  methods: {
    createNazione() {
      apiNazioni.create(newRowPlaceholder, () => {
        instanceMapCountryGrid.updateBoundData("data");
      });
    },
    updateNazione(id, row) {
      apiNazioni.update(id, row, () => {
        instanceMapCountryGrid.updateBoundData("data");
      });
    },
    updateNazioni(rows) {
      apiNazioni.massUpdate(rows, () => {
        instanceMapCountryGrid.updateBoundData("data");
      });
    },
    deleteNazioni(ids) {
      apiNazioni.massDelete(ids, () => {
        instanceMapCountryGrid.updateBoundData("data");
      });
    },
  },
};
</script>