import ajaxCrud from "./ajaxCrud";
import { endpointDescrizioneEsito } from "../../constants";

export default {
  getAll(cb = null) {
    ajaxCrud.getAll(endpointDescrizioneEsito, cb);
  },

  get(id, cb) {
    ajaxCrud.get(endpointDescrizioneEsito, id, cb);
  },

  update(id, data = {}, cb) {
    ajaxCrud.update(endpointDescrizioneEsito, id, data, cb);
  },

  create(data = {}, cb) {
    ajaxCrud.create(endpointDescrizioneEsito, data, cb);
  },

  delete(id, cb) {
    ajaxCrud.delete(endpointDescrizioneEsito, id, cb);
  },

  massDelete(ids, cb) {
    ajaxCrud.massDelete(endpointDescrizioneEsito, ids, cb);
  },

  massUpdate(rows, cb) {
    ajaxCrud.massUpdate(endpointDescrizioneEsito, rows, cb);
  },
};
