import ajaxCrud from "./ajaxCrud";
import { endpointFornitore } from "../../constants";

export default {
  getAll(cb = null) {
    ajaxCrud.getAll(endpointFornitore, cb);
  },

  get(id, cb) {
    ajaxCrud.get(endpointFornitore, id, cb);
  },

  update(id, data = {}, cb) {
    ajaxCrud.update(endpointFornitore, id, data, cb);
  },

  create(data = {}, cb) {
    ajaxCrud.create(endpointFornitore, data, cb);
  },

  delete(id, cb) {
    ajaxCrud.delete(endpointFornitore, id, cb);
  },

  massDelete(ids, cb) {
    ajaxCrud.massDelete(endpointFornitore, ids, cb);
  },

  massUpdate(rows, cb) {
    ajaxCrud.massUpdate(endpointFornitore, rows, cb);
  },
};
