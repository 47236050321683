<template>
  <div id="MobileHeader">
    <b-icon
      icon="list"
      v-b-toggle.MobileSidebar
      scale="2"
      style="color: white"
    ></b-icon>
    <div class="logo">
      <img src="@/assets/images/logo.png" />
      <span>MANAGER</span>
    </div>

    <b-sidebar
      id="MobileSidebar"
      title="Menu"
      bg-variant="dark"
      text-variant="light"
      shadow
      no-header
    >
      <Sidebar toggler="MobileSidebar"></Sidebar>
    </b-sidebar>
  </div>
</template>

<script>
import Sidebar from "@/components/layouts/Sidebar.vue";

export default {
  components: {
    Sidebar,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

#MobileHeader {
  background-color: $primary;
  padding: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .logo {
    display: flex;
    height: 5vh;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    font-weight: bold;
    img {
      height: 4vh;
    }
    span {
      font-size: 8px;
    }
  }
}
</style>