import ajaxCall from "./ajaxCall";
import toastGenerator from "../helpers/toastGenerator";

export default {
  getAll(basePath, cb = null) {
    if (cb == null) {
      return ajaxCall.sendGet(basePath + "/list");
    } else {
      ajaxCall
        .sendGet(basePath + "/list")
        .then((response) => cb(response.data));
    }
  },

  get(basePath, id, cb) {
    ajaxCall.sendGet(basePath + "/" + id).then((response) => cb(response.data));
  },

  update(basePath, id, data = {}, cb) {
    ajaxCall.sendPut(basePath + "/" + id, data).then((response) => {
      toastGenerator.successToast(
        "L'aggiornamento è stato effettuato con successo"
      );
      cb(response.data);
    });
  },

  create(basePath, data = {}, cb) {
    ajaxCall.sendPost(basePath, data).then((response) => {
      toastGenerator.successToast(
        "La creazione è stata effettuata con successo"
      );
      cb(response.data);
    });
  },

  delete(basePath, id, cb) {
    ajaxCall.sendDelete(basePath + "/" + id).then((response) => {
      toastGenerator.successToast(
        "L'eliminazione è stata effettuata con successo"
      );
      cb(response.data);
    });
  },

  massDelete(basePath, ids, cb) {
    ajaxCall
      .sendPost(basePath + "/massDelete", { ids: ids })
      .then((response) => {
        toastGenerator.successToast(
          "L'eliminazione massiva è stata effettuata con successo"
        );
        cb(response.data);
      });
  },

  massUpdate(basePath, rows, cb) {
    ajaxCall
      .sendPost(basePath + "/massUpdate", { rows: rows })
      .then((response) => {
        toastGenerator.successToast(
          "L'aggiornamento massivo è stato effettuato con successo"
        );
        cb(response.data);
      });
  },
};
