<template>
  <div>
    <h3 class="mb-4">Gestione mappatura periodo</h3>
    <div id="mappingPeriodoGrid"></div>
  </div>
</template>
<script>
import Vue from "vue";
import EditableGrid from "@/components/EditableGrid";
import apiPeriodi from "../../services/mapping/periodo";
import jqxgridDefinition from "../../helpers/jqxgridDefinition";

let source = {};
let columns = [];
let instanceMapPeriodGrid = null;
let newRowPlaceholder = null;

export default {
  beforeCreate() {
    var providerSource = jqxgridDefinition.getFornitore().source;
    //eslint-disable-next-line
    let providerAdapter = new jqx.dataAdapter(providerSource, {
      autoBind: true,
    });

    source = jqxgridDefinition.getMappingPeriodo(providerAdapter).source;
    columns = jqxgridDefinition.getMappingPeriodo(providerAdapter).columns;
    newRowPlaceholder =
      jqxgridDefinition.getMappingPeriodo(providerAdapter).newRowPlaceholder;
  },
  mounted() {
    var MapPeriodoGrid = Vue.extend(EditableGrid);
    instanceMapPeriodGrid = new MapPeriodoGrid({
      propsData: {
        columns: columns,
        source: source,
      },
    });
    instanceMapPeriodGrid.$on("createRow", this.createPeriodo);
    instanceMapPeriodGrid.$on("updateRows", this.updatePeriodi);
    instanceMapPeriodGrid.$on("deleteRows", this.deletePeriodi);

    var newDiv = document.createElement("div");
    newDiv.setAttribute("id", "mappingPeriodoDynamicGrid");
    document.getElementById("mappingPeriodoGrid").appendChild(newDiv);
    instanceMapPeriodGrid.$mount("#mappingPeriodoDynamicGrid");
  },
  beforeDestroy() {
    instanceMapPeriodGrid.$destroy();
  },
  methods: {
    createPeriodo() {
      apiPeriodi.create(newRowPlaceholder, () => {
        instanceMapPeriodGrid.updateBoundData("data");
      });
    },
    updatePeriodo(id, row) {
      apiPeriodi.update(id, row, () => {
        instanceMapPeriodGrid.updateBoundData("data");
      });
    },
    updatePeriodi(rows) {
      apiPeriodi.massUpdate(rows, () => {
        instanceMapPeriodGrid.updateBoundData("data");
      });
    },
    deletePeriodi(ids) {
      apiPeriodi.massDelete(ids, () => {
        instanceMapPeriodGrid.updateBoundData("data");
      });
    },
  },
};
</script>