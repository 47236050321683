import ajaxCrud from "./ajaxCrud";
import { endpointDescrizioneScommessa } from "../../constants";

export default {
  getAll(cb = null) {
    ajaxCrud.getAll(endpointDescrizioneScommessa, cb);
  },

  get(id, cb) {
    ajaxCrud.get(endpointDescrizioneScommessa, id, cb);
  },

  update(id, data = {}, cb) {
    ajaxCrud.update(endpointDescrizioneScommessa, id, data, cb);
  },

  create(data = {}, cb) {
    ajaxCrud.create(endpointDescrizioneScommessa, data, cb);
  },

  delete(id, cb) {
    ajaxCrud.delete(endpointDescrizioneScommessa, id, cb);
  },

  massDelete(ids, cb) {
    ajaxCrud.massDelete(endpointDescrizioneScommessa, ids, cb);
  },

  massUpdate(rows, cb) {
    ajaxCrud.massUpdate(endpointDescrizioneScommessa, rows, cb);
  },
};
