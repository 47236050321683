<template>
  <div>
    <h3 class="mb-4">Gestione mappatura scommesse</h3>
    <div id="mappingScommessaGrid"></div>
  </div>
</template>
<script>
import Vue from "vue";
import EditableGrid from "@/components/EditableGrid";
import apiScommesse from "../../services/mapping/scommessa";
import jqxgridDefinition from "../../helpers/jqxgridDefinition";

let source = {};
let columns = [];
let instanceMapMarketGrid = null;
let newRowPlaceholder = null;

export default {
  beforeCreate() {
    var providerSource = jqxgridDefinition.getFornitore().source;
    //eslint-disable-next-line
    let providerAdapter = new jqx.dataAdapter(providerSource, {
      autoBind: true,
    });

    var definition = jqxgridDefinition.getMappingScommessa(providerAdapter);
    source = definition.source;
    columns = definition.columns;
    newRowPlaceholder = definition.newRowPlaceholder;
  },
  mounted() {
    var MapScommessaGrid = Vue.extend(EditableGrid);
    instanceMapMarketGrid = new MapScommessaGrid({
      propsData: {
        columns: columns,
        source: source,
      },
    });
    instanceMapMarketGrid.$on("createRow", this.createScommessa);
    instanceMapMarketGrid.$on("updateRows", this.updateScommesse);
    instanceMapMarketGrid.$on("deleteRows", this.deleteScommesse);

    var newDiv = document.createElement("div");
    newDiv.setAttribute("id", "mappingScommessaDynamicGrid");
    document.getElementById("mappingScommessaGrid").appendChild(newDiv);
    instanceMapMarketGrid.$mount("#mappingScommessaDynamicGrid");
  },
  beforeDestroy() {
    instanceMapMarketGrid.$destroy();
  },
  methods: {
    createScommessa() {
      apiScommesse.create(newRowPlaceholder, () => {
        instanceMapMarketGrid.updateBoundData("data");
      });
    },
    updateScommessa(id, row) {
      apiScommesse.update(id, row, () => {
        instanceMapMarketGrid.updateBoundData("data");
      });
    },
    updateScommesse(rows) {
      apiScommesse.massUpdate(rows, () => {
        instanceMapMarketGrid.updateBoundData("data");
      });
    },
    deleteScommesse(ids) {
      apiScommesse.massDelete(ids, () => {
        instanceMapMarketGrid.updateBoundData("data");
      });
    },
  },
};
</script>